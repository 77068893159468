#page__cabral {
    padding: 50px;
}



@media (max-width: 768px) {
    #page__cabral {
        padding: 20px;

        .img-dupla-2{
            flex-wrap: wrap;
            gap: 50px;

            img{
                max-width: 100%;
            }
        }
    }
}

#page__sobre{
    padding: 50px;
    background-color: $cor-branco-2;

    .menu-lateral .navbar{
        padding: 0 !important;
        align-items: flex-start;
        position: fixed;
        right: 50px;
    }
    .footer-wrapper img{
        padding: 1.5rem 0;
        margin-bottom: -1.5rem;

        background-color: $cor-branco-2;
    }

    .seta-grid{
        display: flex;
        justify-content: end;
        margin-bottom: 100px;
     
    }
}

#container-sobre{
    display: flex;
    justify-content: space-between;
}

.img-sobre{
    max-width: 1550px;
    position: relative;
    &:hover .img-azul-sobre{
        opacity: 0;
        transition: .4s ease-in-out;

    }
}

.img-azul-sobre{
    position: absolute;
    top: 0;
    opacity: 1;
    transition: .4s ease-in-out;

   
}
.img-sobre-mobile{
    display: none;
}
.zero-e-um-img img{
    display: none;
}

#page__sobre{

    .menu-lateral{
        width: 234px;
        height: 100%;
    
        
    
        .x-menu{
            display: flex;
            justify-content: end;
        }
        .menu-lista{
           margin-top: 41px;
            
            li{
                padding: 20px;
                padding-right: 0;
                text-align: right;
                a{
                    font-weight: 500;
                    font-family: 'Exo';
                    font-size: 1.5rem;
                    color: $cor-azul-1;
                    &:hover{
                        color: $cor-roxo-1;
                        transition: .2s;
                        // font-style: italic;
                    }
                }
            }
           
           
        }
    }
}

.container-conteudo{
    max-width: 759px;
    margin: 0 auto;
}

.texto-sobre{
    
    margin-top: 139px ;
    margin-bottom: 121px;
    p{
        margin-top: 32px;
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 27px;
    }
}
#conteudo-sobre{
    .conteudo-wrapper{
        margin-bottom: 152px;
        h1{
            font-weight: 500;
            font-size: 2rem;
            line-height: 43px;
            color: $cor-azul-1;
        }
        .wrapper{
            position: relative;
            padding: 25px  0px 27px 0px;
            
            border-top: 2px solid #000000;
            .img-socio{
                position: absolute;
                top: 0;
                left: -40%;
                opacity: 0;
                transition: .3s;
                width:280px;
                height: 311px;
                img{
                    height: 100%;
                    width: 100%;
                  object-fit: cover;
                }   
            }
            .img-socio-ultima{
                bottom: 0;
                top: unset;
            }
            
            h2{
                font-weight: 500;
                font-size: 1.5rem;
                line-height: 32px;
                color: $cor-azul-1;
                
            }
            &:hover{

                .img-socio{
                    opacity: 1;
                    transition: ease-in-out .5s;
                }
                h2{
                    color: $cor-roxo-1;
                    // font-style: italic;
                    transition: .3s;
                }
            } 
            span{
                font-style: normal;
                font-weight: 500;
                font-size: 1rem;
                line-height: 21px;
            }
            p{
                margin-top: 18px;
                font-weight: 500;
                font-size: 1.25rem;
                line-height: 27px;
            }           
        }
        .wrapper:last-child{
            border-bottom: 2px solid #000000;
        }
    }
}

.premicoes-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 !important;
    padding-bottom: 24px !important;
    border-bottom: unset !important;
    p{
        max-width: 406px;
        margin-top: 0 !important;
        transition: ease-in-out .3s;
    }
    span{
        padding-left: 33px;
        padding-top: 54px;
        border-left: 2px solid #000;
        transition: ease-in-out .3s;
    }
    
}

.premicoes-wrapper:hover{
    p{
        color:$cor-roxo-1 ;

    }
    span{
        color: $cor-roxo-1;
    }
}


@media(max-width:768px){
    #page__sobre{
        padding: 20px;
        &:hover .img-socio{
            display: none;
        }
        #container-sobre{
            height: 60px;
        }
        .menu-lateral .navbar{
            right: 20px;
            align-items: center;
            
        }   
    }
    #page__projetos{
        #container-projeto{
            height: 60px;
        }
        
    }
    .texto-sobre{
        margin-top: 13px;
    }
    #container-sobre{
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        background: #fff;
        padding-left: 20px;
        padding-right: 20px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9;
        .menu-lateral{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
           
            .zero-e-um-img img{
                display: block;
                z-index: 9999;
                position: relative;
            }
        }
    }
    .img-sobre-mobile{
        display: block;
        max-width: 1513px;
        position: relative;
        margin-top: 100px;
        &:hover .img-azul-sobre{
            opacity: 0;
            transition: .4s ease-in-out;
    
        }
    }
    .img-sobre{
        display: none;
    }
    #conteudo-sobre{
        .conteudo-wrapper{
            margin-bottom: 94px;
            padding: 28px 0 28px;
            h1{
                margin-bottom: 20px;
            }
        }
    }
    
    // #conteudo-sobre{
    //     .conteudo-wrapper{
    //         p{
    //             font-size: 0.75rem;
    //         }
    //     }
    // }
    // .texto-sobre p{
    //     font-size: 0.75rem;
    // }

    .seta-grid {
        margin-bottom: 20px !important;
    }
}
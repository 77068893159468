.img-principal {
  position: relative;
  overflow:hidden;
  > img {
    height: calc(100vh - 100px) !important;
    
    width: 100% !important;
    object-fit: cover !important;
  }

  .logo {
    position: absolute;
    top: 50px;
    left: 50px;
  }
}
#conteudo-principal {
  .nome-projeto {
    display: flex;
    justify-content: flex-start;
    border-bottom: 2px solid $cor-azul-1;
    h2 {
      font-family: "Exo";
      font-style: italic;
      font-weight: 500;
      font-size: 32px;
      line-height: 43px;
      color: $cor-azul-1;
      margin-top: 55px;
      padding-right: 50px;
      border-right: 2px solid $cor-azul-1;
    }
    @include d(md){
      flex-direction: column;
      h2 {
        border: none !important;
      }

      p{
        border: none !important;
        padding:  0 !important;
        margin-left: initial;
      }

    }
  }
  .container-conteudo {
    max-width: 759px;
    margin: 0 auto;
  }
  .texto-wrapper {
    margin-top: 84px;
    h2 {
      font-family: "Exo";
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 43px;
      color: $cor-azul-1;
    }
    .texto {
      gap: 20px;
      display: grid;
      p {
        font-family: "Exo";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        color: $cor-preto-1;
      }
    }
  }
  .info-wrapper {
    display: flex;
    margin-top: 84px;
    border-top: 2px solid $cor-azul-1;
    justify-content: space-between;
    margin-bottom: 291.4px;
    .info {
      display: flex;
      padding: 18px 25px 0px 25px;
      border-right: 2px solid $cor-azul-1;
      justify-content: space-between;
      width: 100%;
      p {
        font-family: "Exo";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 27px;
        color: $cor-azul-1;
      }
      span {
        font-family: "Exo";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 27px;
        color: #666666;
      }
    }
  }
  .equipe-wrapper {
    position: relative;
    margin-bottom: 230px;
    h2 {
      font-family: "Exo";
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 43px;
      color: $cor-azul-1;
    }
    .conteudo-equipe {
      display: flex;
      text-align: center;
      align-items: center;
      gap: 6px;
      h3 {
        font-family: "Exo";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: $cor-azul-1;
        margin: 5px !important;
      }
      p {
        font-family: "Exo";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: $cor-preto-1;
      }
    }
    .seta-grid {
      .seta {
        position: absolute;
        right: 55px;
        
        
      }
    }
  }
  .mais-projetos-wrapper {
    display: flex;
    justify-content: flex-start;
    border-top: 2px solid $cor-azul-1;
    h2 {
      font-family: "Exo";
      font-style: italic;
      font-weight: 500;
      font-size: 32px;
      line-height: 43px;
      color: $cor-azul-1;

      padding-right: 50px;
      border-right: 2px solid $cor-azul-1;
    }
  }
}

@media (max-width: 1540px) {
  // .img-principal{
  //     // height: 78vh;
  // }
}

@media (max-width: 768px) {
  
  .info-wrapper{
    border-top: unset !important;
    display: grid !important;
    justify-content: unset !important;
    margin-bottom: 100px !important;
    .info{
      border-right: unset !important;
      padding: unset !important;
      border-top: 2px solid var(--cor-azul-1) !important;
      justify-content: flex-start !important;
      margin-bottom: 25px;
      gap: 0 !important;

      p{
        padding-right: 0 !important;
        border-right: 2px solid var(--cor-azul-1) !important;
        padding-top: 10px !important;
        width: 80px;
      }
      span{
        padding-top: 10px !important;
        padding-left: 20px;
      }
    }
  }
  .img-principal{
    // position: absolute;
    // left: 0;
    // right: 0;
    margin-top: calc(70px - 20px) !important;
    height: 300px !important;
    width: 100vw  !important;
    margin-left: -20px !important;
    margin-right: -20px !important;
    > img {
      width: 100% !important;
      height: 300px !important;
      object-fit: cover;
     
      // height: calc(60vh - 100px) !important;
    }
  }
  #conteudo-principal .texto-wrapper .texto{
    margin-top: 45px;
  }
}

@media (max-width: 876px) {
 /*  #conteudo-principal{
    .nome-projeto{
      margin-top: 430px;
    }
    .equipe-wrapper{
      margin-bottom: 100px;

      h2{
        margin-bottom: 45px;
      }
    }
  } */
  
    
}
#page__mel{
    padding: 50px;

    .img-dupla{
        img{
            max-width: initial;
        }
    }
    .primeira-img-mel{
        display: grid;
        grid-template-columns: 34% 1fr;
        img{
            grid-column: 2;
        }
    }
}


@media(max-width:768px){
    #page__mel{
        padding: 20px;

        .img-dupla{
            img{
                max-width: 100% !important;
            }
        }
     }
}
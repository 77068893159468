#page__agua_sumida {
  padding: 50px;
}



@media (max-width: 768px) {
  #page__agua_sumida {
    padding: 20px;
  }
}

//font
$font-1:'Exo',sans-serif;
$font-2:'Mulish',sans-serif;

//colocar  as variaveis de cor  do projeto no body como variaveis css
body {
    --cor-preto-1: #000;
    --cor-branco-1: #fff;
    --cor-branco-2: #DCE9FF;
    --cor-verde-1: #ACFFCB;
    --cor-verde-2: #003E46;
    --cor-azul-1: #3054FF;
    --cor-azul-2: #27406E;
    --cor-roxo-1: #D078FF;





}

body.dark__mode {
    --cor-preto-1: white;
    


    //reduz levemente o brilho
    img {
        filter: brightness(0.9);
    }
}

//mas utilizar dentro do sass a sintaxe de variavel do sass que é menos verrrbosa

// preto
$cor-preto-1:#{var(--cor-preto-1)};
$cor-branco-1:#{var(--cor-branco-1)};
$cor-branco-2:#{var(--cor-branco-2)};
$cor-verde-1:#{var(--cor-verde-1)};
$cor-verde-2:#{var(--cor-verde-2)};
$cor-azul-1:#{var(--cor-azul-1)};
$cor-azul-2:#{var(--cor-azul-2)};
$cor-roxo-1:#{var(--cor-roxo-1)};


body:not(.open-menu) {
  .menu-lateral {
    pointer-events: none;
  }
}
@media(max-width:1024px){
  body:not(.open-menu) {
    .menu-lateral {
      pointer-events: all;
    }
  }
}

#menu {
  position: fixed;
  top: 56px;
  right: 60px;
  z-index: 1000;

  #hamburger {
    display: block;
    border-top: 3px solid;
    width: 40px;
    color: #fff;

    &::after,
    &::before {
      content: "";
      display: block;
      width: 40px;
      height: 3px;
      background-color: currentColor;
      margin-top: 7px;
      transition: 0.3s;
      position: relative;
    }
  }
}

.botao-fale-conosco {
  position: fixed;
  top: 80px;
  left: 80px;
  z-index: 10;
  color: #fff;
  .fale-conosco {
    opacity: 0;
    position: absolute;
    transition: 0.3s;
    top: 0;
    width: 168px;
    height: 41px;
  }

  &:hover {
    .fale-conosco {
      opacity: 1;
      position: absolute;
      transition: 0.3s;
    }
  }
}

.navbar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 70px;
  
  z-index: 100;
  pointer-events: all;
}

//   .hamburger-menu {
//     // margin-right: auto;
//   }

.menu-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.menu-icon {
  display: block;
  border-top: 3px solid $cor-azul-1;
  width: 40px;

  &::after,
  &::before {
    content: "";
    display: block;
    width: 40px;
    height: 3px;
    background-color: $cor-azul-1;
    margin-top: 5px;
    transition: 0.3s;
    position: relative;
  }
}

.menu-icon::before {
  right: 0;
}

.menu-icon::after {
  right: 0;
}

.menu {
  position: fixed;
  top: 100px;
  right: -200px;
  width: 200px;
  height: 30vh;
  margin: 0;
  padding: 20px;
  padding-right: 50px;
  pointer-events: none;
  margin-top: 37px;

  // background-color: #fff;

  transition: transform 0.3s ease-in-out;
  li {
    padding-top: 20px;

    padding-right: 0;
    text-align: right;
    a {
      font-weight: 500;
      font-family: "Exo";
      font-size: 1.5rem;
      color: $cor-azul-1;
      &:hover {
        color: $cor-roxo-1;
        transition: 0.2s;
        // font-style: italic;
      }
    }
  }
}

.menu-toggle.open .menu-icon {
  transform: rotate(0deg);
  border-top-color: transparent;
  color: $cor-azul-1;
}

.menu-toggle.open .menu-icon::before {
  transform: rotate(135deg);
}

.menu-toggle.open .menu-icon::after {
  transform: rotate(-135deg);
  top: -7px;
}

.menu.open {
  transform: translateX(-200px);
  pointer-events: all;
}

body.page__contato {
  .menu-icon {
    border-top: 3px solid $cor-branco-1;
    &::after,
    &::before {
      background-color: $cor-branco-1;
    }
  }
  .menu {
    // background-color: $cor-azul-1 ;
    top: 100px;
    li {
      a {
        color: $cor-branco-1;
        &:hover {
          color: $cor-roxo-1;
          transition: 0.2s;
        //   font-style: italic;
        }
      }
    }
  }
  .menu-toggle.open .menu-icon {
    color: $cor-azul-1;
  }
}

body.page__home {

  .menu-icon {
    border-top: 3px solid $cor-branco-1;
    &::after,
    &::before {
      background-color: $cor-branco-1;
    }
  }
  .menu {
    //   background-color: $cor-azul-1 ;
    li {
      a {
        color: $cor-branco-1;
        &:hover {
          color: $cor-roxo-1;
          transition: 0.2s;
        //   font-style: italic;
        }
      }
    }
  }
  .menu-toggle.open .menu-icon {
    color: $cor-azul-1;
  }
}

@media (max-width: 768px) {
  .menu-icon {
    display: block;
    border-top: 2px solid $cor-azul-1;
    width: 25px;
  
    &::after,
    &::before {
      content: "";
      display: block;
      width: 25px;
      height: 2px;
      background-color: $cor-azul-1;
      margin-top: 5px;
      transition: 0.3s;
      position: relative;
    }
  }
  #menu {
    position: fixed;
    top: 50px;
    right: 50px;
    z-index: 10;

    #hamburger {
      display: block;
      border-top: 3px solid;
      width: 40px;
      color: #fff;

      &::after,
      &::before {
        content: "";
        display: block;
        width: 40px;
        height: 3px;
        background-color: currentColor;
        margin-top: 7px;
        transition: 0.3s;
        position: relative;
      }
    }
  }
  // #page__home{
  //   #menu{
  //     top: 27px !important;
  //   }
  // }

  .botao-fale-conosco {
    top: 60px;
    left: 50px;
  }
  // body.page__home .menu{
  // 	right: -200px;
  // 	background: blue;
  // }

  .img-branco.open {
    transition: ease-in-out 0s 0.3s;
    filter: brightness(0) saturate(100%) invert(26%) sepia(44%) saturate(5875%) hue-rotate(228deg)
      brightness(100%) contrast(103%);
  }

  .navbar {
    right: 20px !important;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 70px;
    
  }

  .hamburger-menu {
    // margin-right: auto;
    z-index: 10;
  }

  .menu {
    padding-top: 60px !important;
    position: fixed;
    top: 0px !important;
    right: -420px !important;
    height: 100%;
    margin: 0;
    padding: 20px;
    padding-right: 30px;
    background: $cor-branco-2;
    transition: transform 0.3s ease-in-out;
    li {
      a {
        color: $cor-azul-1 !important;
      }
    }
  }

  .menu.open {
    transform: translateX(-420px);
    width: 100% !important;
  }

  .menu-toggle.open .menu-icon {
    &::after,
    &::before {
      background-color: $cor-azul-1 !important ;
    }
  }
  #page__contato {
    .menu{
      padding-top: 80px !important;
    }
  }
}

.menu-projetos {
  position: fixed;
  top: 70px;
  right: -100px;
  z-index: 999999999;
  .menu {
    top: 120px;
    right: -150px;
  }
}
.logos-menu-mobile{
  position: absolute;
  display: flex;
  justify-content: center;
  gap: 35px;
  width: 100%;
  bottom: 20px;
  padding-right: 40px;
  display: none;
}



.fancybox__container {
  z-index: 999999999099 !important;
}

@media (max-width: 768px) {
  .logos-menu-mobile{
    display: flex;
  }
  .logo-projeto {
    display: none;
  }
  .menu-projetos {
    width: 100%;
    z-index: 9;
    background: #fff;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    justify-content: space-between;
    align-items: center;
    height: unset !important;

    .zero-e-um-img{
      z-index: 999;
      position: relative;
      img {
        display: block;
      }
    }
      
  }
  #page__home {
    .navbar{
      height: 50px;
    }
    
  }
    

}




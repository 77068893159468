// ██╗   ██╗████████╗██╗██╗     ██╗████████╗ █████╗ ██████╗ ██╗ ██████╗ ███████╗
// ██║   ██║╚══██╔══╝██║██║     ██║╚══██╔══╝██╔══██╗██╔══██╗██║██╔═══██╗██╔════╝
// ██║   ██║   ██║   ██║██║     ██║   ██║   ███████║██████╔╝██║██║   ██║███████╗
// ██║   ██║   ██║   ██║██║     ██║   ██║   ██╔══██║██╔══██╗██║██║   ██║╚════██║
// ╚██████╔╝   ██║   ██║███████╗██║   ██║   ██║  ██║██║  ██║██║╚██████╔╝███████║
//  ╚═════╝    ╚═╝   ╚═╝╚══════╝╚═╝   ╚═╝   ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝ ╚═════╝ ╚══════╝

@import "./scrollbars"; //scrollbar colorida

@mixin center($left: auto, $right: auto) {
  margin-left: $left;
  margin-right: $right;
}

@mixin img_cover_absolute {
  @include size(100%); //100% tamanho
  @include inset; //centraliza

  position: absolute;
  object-fit: cover;
}

@mixin pseudo($position: initial) {
  content: "";
  @include bg-props-contain; //para gerar a imagem como background contain  e position center

  display: block;
  position: $position;
  pointer-events: none;
}

//background
@mixin bg-props-contain {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

@mixin bg-props-cover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

// Sizing
// ------
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin inset {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

//PX TO REM
$browser-context: 16;

@function rem($pixels, $context: $browser-context) {
  @return #{$pixels/$context}rem;
}

//swiper

.mt-300 {
  margin-top: 300px;
}

.mt-250 {
  margin-top: 250px;
}
.mySwiper1 {
  margin-top: 300px;
}

.mySwiper2 {
  margin-top: 300px;
}

.mySwiper3 {
  margin-top: 300px;
}

.mySwiper4 {
  margin-top: 300px;
  img {
    max-height: 700px;
  }
}

.mySwiper3x {
  margin-top: 300px;
  overflow: initial;

  .swiper-wrapper {
    max-height: 95vh;
    @include d(md2) {
      max-height: initial !important;
      height: auto !important;
      max-width: 100% !important;
    }
  }
  .swiper-slide {
    width: initial;
    @include d(md2) {
      width: 100%;
    }
    img {
      height: 100% !important;
      max-height: 95vh;
      max-width: initial;
      width: auto;
      @include d(md2) {
        max-height: initial !important;
        height: auto !important;
        max-width: 100% !important;
      }
    }
  }
}

.swiper-btns {
  position: relative;
  z-index: 10;
  bottom: -32px;
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  bottom: 50%;
  .swiper-button-next:after,
  .swiper-button-prev:after {
    display: none;
  }

  .swiper-button-next {
    transform: rotate(90deg) translateY(5px);
  }
  .swiper-button-prev {
    transform: rotate(-90deg) translateY(5px);
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: 24px;
    height: 24px;
    z-index: 30;
    cursor: pointer;
    top: unset;
    bottom: 0;
    transition: 0.3s ease;
    @include d(md) {
      width: 14px !important;
    }
  }

  .swiper-button-next:hover {
    transform: rotate(90deg) translateY(0);
  }
  .swiper-button-prev:hover {
    transform: rotate(-90deg) translateY(0);
  }
  .swiper-button-disabled {
    opacity: 0 !important;
  }
}

.mySwiper5 {
  margin-top: 300px;
}

.mySwiper6 {
  margin-top: 300px;
}

img {
  max-width: auto;
  height: auto;
}

.img-1 {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 300px;
}
.img-wrapper {
  display: grid;
  gap: 300px;
  margin-bottom: 200px;
}

.img-dupla {
  display: flex;
  gap: 25px;
  margin-top: 300px;
  align-items: flex-end;

  img {
    margin: 0 auto;
    max-width: 598px !important;
  }
}

.img-central img {
  margin: 0 auto;
  margin-top: 300px;
}
.img-central {
  margin: 0 auto;
}

.img-unica {
  margin-top: 300px;
}

.img-ultima {
  margin-bottom: 300px;
}

.img-unica-esquerda {
  margin-top: 300px;
}

.img-unica-direita {
  margin-top: 300px;
  display: flex;
  justify-content: flex-end;
}

.tres-imgs-wrapper {
  margin-top: 300px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.texto-info {
  max-width: 741px;
  margin: 0 auto;
  margin-top: 300px;
  display: grid;
  gap: 25px;
  h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: $cor-azul-1;
    margin-bottom: 40px;
  }
  .texto-container {
    display: grid;
    gap: 20px;
    p {
      font-weight: 500;
      font-size: 20px;
      line-height: 27px;
    }
  }
}

.img-dupla-2 {
  display: flex;
  justify-content: space-between;
  img {
    margin: 0 auto;
    max-width: 50%;
    height: auto;
  }
}

@media (max-width: 1870px) {
  .img-dupla {
    flex-wrap: wrap;
  }
}

@media (max-width: 1100px) {
  .tres-imgs-wrapper {
    margin-top: 300px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 50px;
    img {
      margin: 0 auto;
    }

    .wrapper-tres img {
      margin: 0 auto;
    }
  }
}

@media (max-width: 876px) {
  //swiper
  .mobile-0e1-logo {
    width: 88px;
  }

  .mySwiper3x {
    margin-top: 100px !important;
    .swiper-slide {
      height: auto !important;
      img {
        height: 100% !important;
      }
    }
  }

  .mySwiper1 {
    margin-top: 100px;
    .swiper-slide {
      width: initial;
      img {
        height: 100% !important;
        max-width: initial;
        width: auto;
      }
    }
  }

  .mySwiper2 {
    margin-top: 100px;
    .swiper-slide {
      width: initial;
      img {
        height: 100% !important;
        max-width: initial;
        width: auto;
      }
    }
  }

  .mySwiper3 {
    margin-top: 100px;
    .swiper-slide {
      width: initial;
      img {
        height: 100% !important;
        max-width: initial;
        width: auto;
      }
    }
  }

  .mySwiper4 {
    margin-top: 100px;
    .swiper-slide {
      // width: initial;
      img {
        height: 100% !important;
        // max-width: initial;
        width: auto;
      }
    }
  }
  .texto-info {
    margin-top: 100px;
  }

  .img-1 {
    margin-bottom: 100px;
  }

  .img-wrapper {
    gap: 100px;
    margin-bottom: 100px;
  }

  .img-dupla {
    display: flex;
    gap: 25px;
    margin-top: 100px;
    align-items: flex-end;

    img {
      margin: 0 auto;
      max-width: 100% !important;
    }
  }
  .mySwiper5 {
    margin-top: 100px;
    .swiper-slide {
      width: initial;
      img {
        height: 100% !important;
        max-width: initial;
        width: auto;
      }
    }
  }

  .mySwiper6 {
    margin-top: 100px;
    .swiper-slide {
      // width: initial;
      img {
        // height: 100% !important;
        // max-width: initial;
        width: auto;
      }
    }
  }

  .tres-imgs-wrapper {
    margin-top: 100px;
  }

  .img-central img {
    margin: 0 auto;
    margin-top: 100px;
  }
  .img-central {
    margin: 0 auto;
  }

  .img-unica {
    margin-top: 100px;
  }

  .img-ultima {
    margin-bottom: 100px;
  }

  .img-unica-esquerda {
    margin-top: 100px;
  }

  .img-unica-direita {
    margin-top: 100px;
  }

  .img-dupla {
    flex-wrap: wrap;
    gap: 50px;

    img {
      max-width: 100%;
    }
  }
}

#conteudo-principal .equipe-wrapper .conteudo-equipe {
  text-align: start !important;
  align-items: baseline !important;
  flex-wrap: wrap !important;
}
#conteudo-principal .equipe-wrapper.info-final .conteudo-equipe {
  text-align: start !important;
  align-items: baseline !important;
  flex-wrap: wrap !important;
}

@include d(md) {
  .nome-projeto .\!mt-\[55px\] {
    margin-top: 32px !important;
    flex-direction: column;
  }
  #conteudo-principal .texto-wrapper {
    margin-top: 32px !important;
  }
  #conteudo-principal .texto-wrapper .texto {
    margin-top: 0px !important;
  }
}

.voltar-page {
  // border-bottom: 2px solid var(--cor-azul-1);

  h2 {
    color: var(--cor-azul-1);
    // border-right: 2px solid var(--cor-azul-1);
    padding-right: 50px;
    font-family: Exo;
    font-size: 32px;
    font-style: italic;
    font-weight: 500;
    line-height: 43px;
    width: max-content;
    transition: 0.2s ease;
    margin-left: auto !important;
    display: flex;
    justify-content: flex-end;
    @include d(md) {
      padding-right: 0px;
    }
    &:hover {
      transform: translateX(-5px);
    }
  }
}

#conteudo-principal .info-final .container-conteudo {
  max-width: max-content !important;
}

.swiper-wrapper {
  user-select: none;
}

.mais-projetos-wrapper {
  // flex-direction: column !important;
  display: block !important;

  h2 {
    display: inline-flex;
    width: max-content;
  }
  .swiper {
    margin-top: 4rem !important;
    .img-wrapper {
      gap: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  .swiper-slide {
    width: 350px;
    @include d(md2) {
      width: 90%;
      max-width: 350px;
    }

    .projeto-wrapper {
      height: 100%;
      cursor: pointer;
      // width: 300px;
      // min-width: 300px;

      .img-wrapper {
        --size: clamp(180px, 30vh, 17.6875rem);
        display: block !important;
        height: 100%;
        width: 100%;
        // width: var(--size);
        height: var(--size);
        position: relative;
        overflow: hidden;
      }

      &:hover {
        img {
          transform: scale(1.1);
        }
      }

      img {
        position: absolute;
        inset: 0;
        margin: auto;
        height: 100%;
        width: 100%;
        transition: ease-out 0.5s;
        object-fit: cover;
      }

      .descricao-projeto {
        display: flex;
        justify-content: space-between;
        padding: 9px 0 6px;
        border-bottom: 2px solid $cor-azul-1;
        font-family: "Exo";
        color: $cor-azul-1;
        transition: 0.3s;
        p {
          transition: 0.3s;
        }
      }

      &:hover .descricao-projeto {
        border-bottom: 2px solid $cor-roxo-1;
        color: $cor-roxo-1;
        transition: 0.3s;
        p {
          // font-style: italic;
          transition: 0.3s;
        }
      }
    }
  }
}

.btn-show {
  transition: transform 0.2s ease;
  width: max-content;
  .icon-container {
    // clip-path: circle(20% at 32px);
    display: flex;
    align-items: center;
    width: 52px;
    height: 52px;
    // width: 50px;
    padding: 8px;
    background-color: white !important;
    border-radius: 16px;
    overflow: hidden;
    transition: 0.4s cubic-bezier(0.65, 0, 0.35, 1);
    flex-shrink: 0;

    .fale-wrapper {
      overflow: hidden;
      width: max-content;
      flex-shrink: 0;
    }
    svg {
      width: 32px;
      height: 32px;
      flex-shrink: 0;
      --cor1: #5a5a5a;
    }

    p {
      padding-left: 8px;
      color: $cor-branco-1;
      font-family: "Exo";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      transform: translateX(-120px);
      transition: 0.3s cubic-bezier(0.65, 0, 0.35, 1);
      white-space: nowrap;
      transition-delay: 200ms;
    }
  }
}
.btn-show:hover {
  .icon-container {
    width: 180px;
    background-color: #3054ff !important;

    p {
      transform: translateX(0px);
      z-index: 1;
    }
  }
  svg {
    --cor1: white;
  }
}

.btn-show:active {
  transform: scale(1.05);
}

.menu-lateral {
  width: 234px;

  .x-menu {
    display: flex;
    justify-content: end;
  }
  .menu-lista {
    margin-top: 41px;

    li {
      padding: 20px;
      padding-right: 0;
      text-align: right;
      a {
        font-weight: 500;
        font-family: "Exo";
        font-size: 1.5rem;
        color: $cor-azul-1;
        &:hover {
          color: $cor-roxo-1;
          transition: 0.2s;
          // font-style: italic;
        }
      }
    }
  }
}

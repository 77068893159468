.footer-wrapper {
  display: flex;
  justify-content: space-between;
}

.footer-bottom {
  margin-top: 32px;
  .footer-wrapper {
    background: var(--cor-branco-1);

    display: flex;
    justify-content: space-between;
    position: static;
    width: 100%;
    bottom:30px;
    left: 0;
    padding: 1rem 0px 3.1rem;
    // z-index: 9;
  }
}

#page__contato {
  background-color: $cor-azul-1;
  padding: 50px;
  height: 100vh;
  overflow-y: auto !important;
  display: flex;
  flex-direction: column;

  .navbar{
    justify-content: flex-end;
    padding: 0 30px;
    height: 60px;
  }

  #container-projeto {
    position: fixed;
    right: 20px;
    top: 40px;
  }
  #contato {
    padding: 0;
    margin-top: 100px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    margin-left: auto;
    margin-right: 15vw;
    margin-bottom: 10vh;
  }

  .logo {
    img {
      height: 80px;
    }
  }
}
#page__contato{
  
  // .navbar{
  //   padding-right: 20px;
  // }

  .menu-lateral {
    width: 234px;
    height: 100%;
  
    .x-menu {
      display: flex;
      justify-content: end;
    }
  
    .menu-lista {
      margin-top: 41px;
  
      li {
        padding: 20px;
        padding-right: 0;
        text-align: right;
        a {
          font-weight: 500;
          font-family: "Exo";
          font-size: 24px;
          color: $cor-branco-1;
          &:hover {
            color: $cor-verde-1;
            transition: 0.2s;
          }
        }
      }
    }
  }
}


#contato {
  max-width: 744px;
  margin: 0 auto;
  position: unset !important;

  .contato-wrapper {
    padding-bottom: 26px;
    margin-top: 24px;
    border-bottom: 2px solid $cor-branco-1;
    h2 {
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      color: $cor-verde-1;
      margin-bottom: 13px;
    }
    p,
    a {
      font-weight: 500;
      font-size: 16px;
      line-height: 27px;
      color: $cor-branco-1;
      &:hover {
        color: $cor-roxo-1;
        transition: 0.2s;
      }
    }
  }
}

.footer-wrapper {
  position: fixed;
  bottom: 50px;
  width: 100%;
  padding-right: 100px;
}

@media (max-width: 768px) {
  #page__contato{
    .logo{
      margin-top: -20px;
    }
    #container-projeto {
      top: 20px;
      .navbar{
        padding: 0;
      }
    }

    padding: 20px;
    .navbar{
      align-items: unset;
    }
    #contato{
      margin: unset;
      .contato-wrapper {
        padding-bottom: 26px;
        margin-top: 24px;
        border-bottom: 2px solid $cor-branco-1;
        h2 {
          font-weight: 500;
          font-size: 14px;
          line-height: 32px;
          color: $cor-verde-1;
          margin-bottom: 13px;
        }
        p,
        a {
          font-weight: 500;
          font-size: 12px;
          line-height: 27px;
          color: $cor-branco-1;
        }
      }
    }
  }
  .menu-lateral .navbar {
    .hamburger-menu {
      margin-right: unset !important;
    }
  }
}

#page__senge{
    padding: 50px;

    .img-dupla{
        img{
            max-width: initial;
        }
    }
    // .conteudo-tx{
    //     display: flex;
    //     flex-direction: column;
    //     gap: 32px;
    //     max-width: 750px;
    // }
}


@media(max-width:768px){
    #page__senge{
        padding: 20px;

        .img-dupla{
            img{
                max-width: 100% !important;
            }
        }
        .conteudo-tx{
            display: grid;
        }
     }
}

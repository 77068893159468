#page__xangri_la{
    padding: 50px;
}


@media(max-width:768px){
    #page__xangri_la{
        padding: 20px;
     }
}


#footer{
    padding-bottom: 2.25rem;
    position: relative;
    background: #0A0B21;
    .footer-wrapper{
        display: flex;
        justify-content: space-between;
        @include d(sm){
            flex-direction: column;
            gap: 1rem;
        }
        .copy{
            @include d(sm){
                width: 206px;
                align-self: center;
            }
            h3{
                font-size: .75rem;
                font-weight: 600;
                line-height: 133%;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: var(--primary-pure-10);
                margin-bottom: 1rem;
                @include d(sm){
                    font-size: .625rem;
                    text-align: center;
                }
            }
        }
        .logo{
            @include d(sm){
                align-self: center;
            }
        }
    }
}
#page__casa_em_canela{
    padding: 50px;
}







@media(max-width:768px){
    #page__casa_em_canela{
        padding: 20px;

        .mySwiper1 {
            margin-top: 100px;
            .swiper-slide {
              width: initial;
              img {
                height: 100% !important;
                max-width: 100% !important;
                width: auto;
              }
            }
          }
        
          .mySwiper2 {
            margin-top: 100px;
            .swiper-slide {
              width: initial;
              img {
                height: 100% !important;
                max-width: 100% !important;
                width: auto;
              }
            }
          }
     }
}
body {
  --cor-preto-1: #000;
  --cor-branco-1: #fff;
  --cor-branco-2: #dce9ff;
  --cor-verde-1: #acffcb;
  --cor-verde-2: #003e46;
  --cor-azul-1: #3054ff;
  --cor-azul-2: #27406e;
  --cor-roxo-1: #d078ff;
}

body.dark__mode {
  --cor-preto-1: white;
}

body.dark__mode img {
  filter: brightness(.9);
}

body, p, h1, h2, ul, li {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

ol {
  padding: 0;
}

a {
  color: #000;
  text-decoration: none;
}

:after, :before {
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
}

button {
  background: initial;
  border: initial;
  -webkit-appearance: none;
  font-family: Exo, sans-serif;
}

button:active {
  border-style: initial;
}

button:focus {
  outline: initial;
}

button[type="button"], button[type="submit"], button[type="reset"] {
  -webkit-appearance: none;
}

button[type="submit"] {
  appearance: none;
}

img {
  object-fit: contain;
  max-width: 100%;
  display: block;
}

fieldset {
  -webkit-margin-start: 0px;
  -moz-margin-start: 0px;
  -webkit-margin-end: 0px;
  -moz-margin-end: 0px;
  border: 2px #0000;
  border-image: initial;
  min-inline-size: min-content;
  margin-inline: 0;
}

figure {
  margin-block: 0;
  margin-inline: 0;
  display: block;
}

:focus {
  outline: 0 #0000 !important;
}

input[type="submit"] {
  appearance: none;
}

input, [type="button"], select {
  -webkit-appearance: none;
}

#page__projetos {
  flex-direction: column;
  min-height: 100vh;
  padding: 25px 50px 0;
  display: flex;
}

#page__projetos .img-wrapper {
  gap: unset !important;
  margin-bottom: unset !important;
  display: unset !important;
}

#page__projetos .container-projetos-mobile {
  display: none;
}

#page__projetos #container-projeto {
  justify-content: space-between;
  gap: 100px;
  display: flex;
}

#page__projetos .container-projeto {
  gap: 40px;
  height: 100%;
  display: grid;
}

#page__projetos .projeto-wrapper {
  cursor: pointer;
  max-width: 283px;
  height: 100%;
}

#page__projetos .projeto-wrapper .img-wrapper {
  --size: clamp(180px, 26vh, 17.6875rem);
  width: 100%;
  height: 100%;
  height: var(--size);
  position: relative;
  overflow: hidden;
  display: block !important;
}

#page__projetos .projeto-wrapper:hover img {
  transform: scale(1.1);
}

#page__projetos .projeto-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  margin: auto;
  transition: all .5s ease-out;
  inset: 0;
}

#page__projetos .projeto-wrapper .descricao-projeto {
  border-bottom: 2px solid var(--cor-azul-1);
  color: var(--cor-azul-1);
  justify-content: space-between;
  padding: 9px 0 6px;
  font-family: Exo;
  transition: all .3s;
  display: flex;
}

#page__projetos .projeto-wrapper .descricao-projeto p {
  transition: all .3s;
}

#page__projetos .projeto-wrapper:hover .descricao-projeto {
  border-bottom: 2px solid var(--cor-roxo-1);
  color: var(--cor-roxo-1);
  transition: all .3s;
}

#page__projetos .projeto-wrapper:hover .descricao-projeto p {
  transition: all .3s;
}

#page__projetos .menu-lateral {
  width: 234px;
  height: 100%;
}

#page__projetos .menu-lateral .zero-e-um-img img {
  display: none;
}

#page__projetos .menu-lateral .x-menu {
  justify-content: end;
  display: flex;
}

#page__projetos .menu-lateral .menu-lista {
  margin-top: 41px;
}

#page__projetos .menu-lateral .menu-lista li {
  text-align: right;
  padding: 20px 0 20px 20px;
}

#page__projetos .menu-lateral .menu-lista li a {
  color: var(--cor-azul-1);
  font-family: Exo;
  font-size: 1.5rem;
  font-weight: 500;
}

#page__projetos .menu-lateral .menu-lista li a:hover {
  color: var(--cor-roxo-1);
  transition: all .2s;
}

#page__projetos .sub-menu {
  margin-top: 32px;
  margin-bottom: 2rem;
}

#page__projetos .sub-menu .lista {
  border-bottom: 2px solid var(--cor-azul-1);
  -webkit-user-select: none;
  user-select: none;
  justify-content: flex-start;
  padding-bottom: 11px;
  display: flex;
}

#page__projetos .sub-menu .lista li {
  cursor: pointer;
  transition: all .2s;
}

#page__projetos .sub-menu .lista li.active a {
  color: var(--cor-roxo-1);
}

#page__projetos .sub-menu .lista a {
  color: var(--cor-azul-1);
  border-right: 2px solid var(--cor-azul-1);
  padding-bottom: 13px;
  padding-left: 24px;
  padding-right: 24px;
  font-family: Exo;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 27px;
}

#page__projetos .mySwiper {
  width: 100%;
  padding-bottom: 93px;
  height: calc(50% - 15px) !important;
}

#page__projetos .mySwiper .swiper-wrapper {
  flex-direction: row !important;
  align-items: baseline !important;
}

#page__projetos .navbar {
  justify-content: flex-end;
  padding-right: 0;
  position: fixed;
  right: 50px;
  height: 90px !important;
}

#page__projetos #projetos-mobile {
  display: none;
}

@media (width <= 768px) {
  .footer-wrapper {
    display: none !important;
  }

  #page__projetos {
    padding: 20px;
  }

  #page__projetos .navbar {
    right: 20px;
  }

  #page__projetos #container-projeto {
    z-index: 5;
    background: #fff;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    position: fixed;
    top: 0;
    left: 0;
  }

  #page__projetos .menu-lateral {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    display: flex;
  }

  #page__projetos .menu-lateral .zero-e-um-img img {
    z-index: 9999;
    display: block;
    position: relative;
  }

  #page__projetos .mySwiper {
    display: none;
  }

  #page__projetos .sub-menu {
    margin-bottom: 33px;
    margin-top: 96px !important;
  }

  #page__projetos .sub-menu .lista a {
    padding-left: 15px;
    padding-right: 15px;
  }

  #page__projetos .container-projetos-mobile {
    grid-template-columns: 1fr 1fr;
    gap: 25px;
    display: grid;
  }

  #page__projetos .container-projetos-mobile .projeto-wrapper {
    max-width: 100%;
  }

  #page__projetos .container-projetos-mobile .projeto-wrapper img {
    width: 100%;
  }

  #page__projetos #projetos-mobile {
    display: block;
    position: relative;
    z-index: 1 !important;
  }

  #page__projetos #projetos-mobile .container-mobile-projetos {
    grid-template-columns: 1fr 1fr;
    gap: 25px;
    width: 100%;
    margin: 0 auto;
    display: grid;
  }
}

@media (width <= 480px) {
  #page__projetos .container-projetos-mobile, #page__projetos #projetos-mobile .container-mobile-projetos {
    grid-template-columns: 1fr;
  }
}

#page__projetos .swiper-btns {
  z-index: 10 !important;
  position: relative !important;
  bottom: -72px !important;
}

#page__projetos .swiper-btns .swiper-button-next, #page__projetos .swiper-btns .swiper-button-prev {
  width: 16.36px !important;
  height: 26.87px !important;
}

#page__contato {
  background-color: var(--cor-azul-1);
  flex-direction: column;
  height: 100vh;
  padding: 50px;
  display: flex;
  overflow-y: auto !important;
}

#page__contato .navbar {
  justify-content: flex-end;
  height: 60px;
  padding: 0 30px;
}

#page__contato #container-projeto {
  position: fixed;
  top: 40px;
  right: 20px;
}

#page__contato #contato {
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  width: 100%;
  margin: 100px 15vw 10vh auto;
  padding: 0;
  display: flex;
}

#page__contato .logo img {
  height: 80px;
}

#page__contato .menu-lateral {
  width: 234px;
  height: 100%;
}

#page__contato .menu-lateral .x-menu {
  justify-content: end;
  display: flex;
}

#page__contato .menu-lateral .menu-lista {
  margin-top: 41px;
}

#page__contato .menu-lateral .menu-lista li {
  text-align: right;
  padding: 20px 0 20px 20px;
}

#page__contato .menu-lateral .menu-lista li a {
  color: var(--cor-branco-1);
  font-family: Exo;
  font-size: 24px;
  font-weight: 500;
}

#page__contato .menu-lateral .menu-lista li a:hover {
  color: var(--cor-verde-1);
  transition: all .2s;
}

#contato {
  max-width: 744px;
  margin: 0 auto;
  position: unset !important;
}

#contato .contato-wrapper {
  border-bottom: 2px solid var(--cor-branco-1);
  margin-top: 24px;
  padding-bottom: 26px;
}

#contato .contato-wrapper h2 {
  color: var(--cor-verde-1);
  margin-bottom: 13px;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
}

#contato .contato-wrapper p, #contato .contato-wrapper a {
  color: var(--cor-branco-1);
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
}

#contato .contato-wrapper p:hover, #contato .contato-wrapper a:hover {
  color: var(--cor-roxo-1);
  transition: all .2s;
}

.footer-wrapper {
  width: 100%;
  padding-right: 100px;
  position: fixed;
  bottom: 50px;
}

@media (width <= 768px) {
  #page__contato {
    padding: 20px;
  }

  #page__contato .logo {
    margin-top: -20px;
  }

  #page__contato #container-projeto {
    top: 20px;
  }

  #page__contato #container-projeto .navbar {
    padding: 0;
  }

  #page__contato .navbar {
    align-items: unset;
  }

  #page__contato #contato {
    margin: unset;
  }

  #page__contato #contato .contato-wrapper {
    border-bottom: 2px solid var(--cor-branco-1);
    margin-top: 24px;
    padding-bottom: 26px;
  }

  #page__contato #contato .contato-wrapper h2 {
    color: var(--cor-verde-1);
    margin-bottom: 13px;
    font-size: 14px;
    font-weight: 500;
    line-height: 32px;
  }

  #page__contato #contato .contato-wrapper p, #page__contato #contato .contato-wrapper a {
    color: var(--cor-branco-1);
    font-size: 12px;
    font-weight: 500;
    line-height: 27px;
  }

  .menu-lateral .navbar .hamburger-menu {
    margin-right: unset !important;
  }
}

#page__sobre {
  background-color: var(--cor-branco-2);
  padding: 50px;
}

#page__sobre .menu-lateral .navbar {
  align-items: flex-start;
  position: fixed;
  right: 50px;
  padding: 0 !important;
}

#page__sobre .footer-wrapper img {
  background-color: var(--cor-branco-2);
  margin-bottom: -1.5rem;
  padding: 1.5rem 0;
}

#page__sobre .seta-grid {
  justify-content: end;
  margin-bottom: 100px;
  display: flex;
}

#container-sobre {
  justify-content: space-between;
  display: flex;
}

.img-sobre {
  max-width: 1550px;
  position: relative;
}

.img-sobre:hover .img-azul-sobre {
  opacity: 0;
  transition: all .4s ease-in-out;
}

.img-azul-sobre {
  opacity: 1;
  transition: all .4s ease-in-out;
  position: absolute;
  top: 0;
}

.img-sobre-mobile, .zero-e-um-img img {
  display: none;
}

#page__sobre .menu-lateral {
  width: 234px;
  height: 100%;
}

#page__sobre .menu-lateral .x-menu {
  justify-content: end;
  display: flex;
}

#page__sobre .menu-lateral .menu-lista {
  margin-top: 41px;
}

#page__sobre .menu-lateral .menu-lista li {
  text-align: right;
  padding: 20px 0 20px 20px;
}

#page__sobre .menu-lateral .menu-lista li a {
  color: var(--cor-azul-1);
  font-family: Exo;
  font-size: 1.5rem;
  font-weight: 500;
}

#page__sobre .menu-lateral .menu-lista li a:hover {
  color: var(--cor-roxo-1);
  transition: all .2s;
}

.container-conteudo {
  max-width: 759px;
  margin: 0 auto;
}

.texto-sobre {
  margin-top: 139px;
  margin-bottom: 121px;
}

.texto-sobre p {
  margin-top: 32px;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 27px;
}

#conteudo-sobre .conteudo-wrapper {
  margin-bottom: 152px;
}

#conteudo-sobre .conteudo-wrapper h1 {
  color: var(--cor-azul-1);
  font-size: 2rem;
  font-weight: 500;
  line-height: 43px;
}

#conteudo-sobre .conteudo-wrapper .wrapper {
  border-top: 2px solid #000;
  padding: 25px 0 27px;
  position: relative;
}

#conteudo-sobre .conteudo-wrapper .wrapper .img-socio {
  opacity: 0;
  width: 280px;
  height: 311px;
  transition: all .3s;
  position: absolute;
  top: 0;
  left: -40%;
}

#conteudo-sobre .conteudo-wrapper .wrapper .img-socio img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

#conteudo-sobre .conteudo-wrapper .wrapper .img-socio-ultima {
  bottom: 0;
  top: unset;
}

#conteudo-sobre .conteudo-wrapper .wrapper h2 {
  color: var(--cor-azul-1);
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 32px;
}

#conteudo-sobre .conteudo-wrapper .wrapper:hover .img-socio {
  opacity: 1;
  transition: all .5s ease-in-out;
}

#conteudo-sobre .conteudo-wrapper .wrapper:hover h2 {
  color: var(--cor-roxo-1);
  transition: all .3s;
}

#conteudo-sobre .conteudo-wrapper .wrapper span {
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

#conteudo-sobre .conteudo-wrapper .wrapper p {
  margin-top: 18px;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 27px;
}

#conteudo-sobre .conteudo-wrapper .wrapper:last-child {
  border-bottom: 2px solid #000;
}

.premicoes-wrapper {
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
  border-bottom: unset !important;
  padding: 0 0 24px !important;
}

.premicoes-wrapper p {
  max-width: 406px;
  transition: all .3s ease-in-out;
  margin-top: 0 !important;
}

.premicoes-wrapper span {
  border-left: 2px solid #000;
  padding-top: 54px;
  padding-left: 33px;
  transition: all .3s ease-in-out;
}

.premicoes-wrapper:hover p, .premicoes-wrapper:hover span {
  color: var(--cor-roxo-1);
}

@media (width <= 768px) {
  #page__sobre {
    padding: 20px;
  }

  #page__sobre:hover .img-socio {
    display: none;
  }

  #page__sobre #container-sobre {
    height: 60px;
  }

  #page__sobre .menu-lateral .navbar {
    align-items: center;
    right: 20px;
  }

  #page__projetos #container-projeto {
    height: 60px;
  }

  .texto-sobre {
    margin-top: 13px;
  }

  #container-sobre {
    z-index: 9;
    background: #fff;
    flex-direction: column-reverse;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
  }

  #container-sobre .menu-lateral {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    display: flex;
  }

  #container-sobre .menu-lateral .zero-e-um-img img {
    z-index: 9999;
    display: block;
    position: relative;
  }

  .img-sobre-mobile {
    max-width: 1513px;
    margin-top: 100px;
    display: block;
    position: relative;
  }

  .img-sobre-mobile:hover .img-azul-sobre {
    opacity: 0;
    transition: all .4s ease-in-out;
  }

  .img-sobre {
    display: none;
  }

  #conteudo-sobre .conteudo-wrapper {
    margin-bottom: 94px;
    padding: 28px 0;
  }

  #conteudo-sobre .conteudo-wrapper h1 {
    margin-bottom: 20px;
  }

  .seta-grid {
    margin-bottom: 20px !important;
  }
}

#page__agua_sumida {
  padding: 50px;
}

@media (width <= 768px) {
  #page__agua_sumida {
    padding: 20px;
  }
}

#page__casa_em_canela {
  padding: 50px;
}

@media (width <= 768px) {
  #page__casa_em_canela {
    padding: 20px;
  }

  #page__casa_em_canela .mySwiper1 {
    margin-top: 100px;
  }

  #page__casa_em_canela .mySwiper1 .swiper-slide {
    width: initial;
  }

  #page__casa_em_canela .mySwiper1 .swiper-slide img {
    width: auto;
    max-width: 100% !important;
    height: 100% !important;
  }

  #page__casa_em_canela .mySwiper2 {
    margin-top: 100px;
  }

  #page__casa_em_canela .mySwiper2 .swiper-slide {
    width: initial;
  }

  #page__casa_em_canela .mySwiper2 .swiper-slide img {
    width: auto;
    max-width: 100% !important;
    height: 100% !important;
  }
}

#page__xangri_la {
  padding: 50px;
}

@media (width <= 768px) {
  #page__xangri_la {
    padding: 20px;
  }
}

#page__eixao {
  padding: 50px;
}

@media (width <= 768px) {
  #page__eixao {
    padding: 20px;
  }
}

#page__basalto {
  padding: 50px;
}

@media (width <= 768px) {
  #page__basalto {
    padding: 20px;
  }

  #page__basalto .img-dupla-2 {
    flex-wrap: wrap;
    gap: 50px;
  }

  #page__basalto .img-dupla-2 img {
    max-width: 100%;
  }
}

#page__vistavino {
  padding: 50px;
}

@media (width <= 768px) {
  #page__vistavino {
    padding: 20px;
  }
}

#page__apt_america {
  padding: 50px;
}

@media (width <= 768px) {
  #page__apt_america {
    padding: 20px;
  }
}

#page__lajeado {
  padding: 50px;
}

@media (width <= 768px) {
  #page__lajeado {
    padding: 20px;
  }
}

#page__celeiro {
  padding: 50px;
}

@media (width <= 768px) {
  #page__celeiro {
    padding: 20px;
  }
}

#page__ecletica {
  padding: 50px;
}

#page__ecletica .texto-info {
  gap: 25px;
  display: grid;
}

@media (width <= 768px) {
  #page__ecletica {
    padding: 20px;
  }

  #page__ecletica .mySwiper3x .swiper-slide {
    width: 100% !important;
  }

  #page__ecletica .mySwiper3x .swiper-slide img {
    max-width: 100% !important;
  }
}

#page__bento_e_luke {
  padding: 50px;
}

@media (width <= 768px) {
  #page__bento_e_luke {
    padding: 20px;
  }

  #page__bento_e_luke .img-dupla-2 {
    flex-wrap: wrap;
    gap: 50px;
  }

  #page__bento_e_luke .img-dupla-2 img {
    max-width: 100%;
  }
}

#page__cabral {
  padding: 50px;
}

@media (width <= 768px) {
  #page__cabral {
    padding: 20px;
  }

  #page__cabral .img-dupla-2 {
    flex-wrap: wrap;
    gap: 50px;
  }

  #page__cabral .img-dupla-2 img {
    max-width: 100%;
  }
}

#page__mel {
  padding: 50px;
}

#page__mel .img-dupla img {
  max-width: initial;
}

#page__mel .primeira-img-mel {
  grid-template-columns: 34% 1fr;
  display: grid;
}

#page__mel .primeira-img-mel img {
  grid-column: 2;
}

@media (width <= 768px) {
  #page__mel {
    padding: 20px;
  }

  #page__mel .img-dupla img {
    max-width: 100% !important;
  }
}

#page__oden {
  padding: 50px;
}

#page__oden .img-dupla img {
  max-width: initial;
}

@media (width <= 768px) {
  #page__oden {
    padding: 20px;
  }

  #page__oden .img-dupla img {
    max-width: 100% !important;
  }
}

#page__tito {
  padding: 50px;
}

#page__tito .img-dupla img {
  max-width: initial;
}

@media (width <= 768px) {
  #page__tito {
    padding: 20px;
  }

  #page__tito .img-dupla img {
    max-width: 100% !important;
  }
}

body[data-aos-duration="50"] [data-aos], [data-aos][data-aos][data-aos-duration="50"] {
  transition-duration: 50ms;
}

body[data-aos-delay="50"] [data-aos], [data-aos][data-aos][data-aos-delay="50"] {
  transition-delay: 0s;
}

body[data-aos-delay="50"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="50"].aos-animate {
  transition-delay: 50ms;
}

body[data-aos-duration="100"] [data-aos], [data-aos][data-aos][data-aos-duration="100"] {
  transition-duration: .1s;
}

body[data-aos-delay="100"] [data-aos], [data-aos][data-aos][data-aos-delay="100"] {
  transition-delay: 0s;
}

body[data-aos-delay="100"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="100"].aos-animate {
  transition-delay: .1s;
}

body[data-aos-duration="150"] [data-aos], [data-aos][data-aos][data-aos-duration="150"] {
  transition-duration: .15s;
}

body[data-aos-delay="150"] [data-aos], [data-aos][data-aos][data-aos-delay="150"] {
  transition-delay: 0s;
}

body[data-aos-delay="150"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="150"].aos-animate {
  transition-delay: .15s;
}

body[data-aos-duration="200"] [data-aos], [data-aos][data-aos][data-aos-duration="200"] {
  transition-duration: .2s;
}

body[data-aos-delay="200"] [data-aos], [data-aos][data-aos][data-aos-delay="200"] {
  transition-delay: 0s;
}

body[data-aos-delay="200"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="200"].aos-animate {
  transition-delay: .2s;
}

body[data-aos-duration="250"] [data-aos], [data-aos][data-aos][data-aos-duration="250"] {
  transition-duration: .25s;
}

body[data-aos-delay="250"] [data-aos], [data-aos][data-aos][data-aos-delay="250"] {
  transition-delay: 0s;
}

body[data-aos-delay="250"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="250"].aos-animate {
  transition-delay: .25s;
}

body[data-aos-duration="300"] [data-aos], [data-aos][data-aos][data-aos-duration="300"] {
  transition-duration: .3s;
}

body[data-aos-delay="300"] [data-aos], [data-aos][data-aos][data-aos-delay="300"] {
  transition-delay: 0s;
}

body[data-aos-delay="300"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="300"].aos-animate {
  transition-delay: .3s;
}

body[data-aos-duration="350"] [data-aos], [data-aos][data-aos][data-aos-duration="350"] {
  transition-duration: .35s;
}

body[data-aos-delay="350"] [data-aos], [data-aos][data-aos][data-aos-delay="350"] {
  transition-delay: 0s;
}

body[data-aos-delay="350"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="350"].aos-animate {
  transition-delay: .35s;
}

body[data-aos-duration="400"] [data-aos], [data-aos][data-aos][data-aos-duration="400"] {
  transition-duration: .4s;
}

body[data-aos-delay="400"] [data-aos], [data-aos][data-aos][data-aos-delay="400"] {
  transition-delay: 0s;
}

body[data-aos-delay="400"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="400"].aos-animate {
  transition-delay: .4s;
}

body[data-aos-duration="450"] [data-aos], [data-aos][data-aos][data-aos-duration="450"] {
  transition-duration: .45s;
}

body[data-aos-delay="450"] [data-aos], [data-aos][data-aos][data-aos-delay="450"] {
  transition-delay: 0s;
}

body[data-aos-delay="450"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="450"].aos-animate {
  transition-delay: .45s;
}

body[data-aos-duration="500"] [data-aos], [data-aos][data-aos][data-aos-duration="500"] {
  transition-duration: .5s;
}

body[data-aos-delay="500"] [data-aos], [data-aos][data-aos][data-aos-delay="500"] {
  transition-delay: 0s;
}

body[data-aos-delay="500"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="500"].aos-animate {
  transition-delay: .5s;
}

body[data-aos-duration="550"] [data-aos], [data-aos][data-aos][data-aos-duration="550"] {
  transition-duration: .55s;
}

body[data-aos-delay="550"] [data-aos], [data-aos][data-aos][data-aos-delay="550"] {
  transition-delay: 0s;
}

body[data-aos-delay="550"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="550"].aos-animate {
  transition-delay: .55s;
}

body[data-aos-duration="600"] [data-aos], [data-aos][data-aos][data-aos-duration="600"] {
  transition-duration: .6s;
}

body[data-aos-delay="600"] [data-aos], [data-aos][data-aos][data-aos-delay="600"] {
  transition-delay: 0s;
}

body[data-aos-delay="600"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="600"].aos-animate {
  transition-delay: .6s;
}

body[data-aos-duration="650"] [data-aos], [data-aos][data-aos][data-aos-duration="650"] {
  transition-duration: .65s;
}

body[data-aos-delay="650"] [data-aos], [data-aos][data-aos][data-aos-delay="650"] {
  transition-delay: 0s;
}

body[data-aos-delay="650"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="650"].aos-animate {
  transition-delay: .65s;
}

body[data-aos-duration="700"] [data-aos], [data-aos][data-aos][data-aos-duration="700"] {
  transition-duration: .7s;
}

body[data-aos-delay="700"] [data-aos], [data-aos][data-aos][data-aos-delay="700"] {
  transition-delay: 0s;
}

body[data-aos-delay="700"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="700"].aos-animate {
  transition-delay: .7s;
}

body[data-aos-duration="750"] [data-aos], [data-aos][data-aos][data-aos-duration="750"] {
  transition-duration: .75s;
}

body[data-aos-delay="750"] [data-aos], [data-aos][data-aos][data-aos-delay="750"] {
  transition-delay: 0s;
}

body[data-aos-delay="750"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="750"].aos-animate {
  transition-delay: .75s;
}

body[data-aos-duration="800"] [data-aos], [data-aos][data-aos][data-aos-duration="800"] {
  transition-duration: .8s;
}

body[data-aos-delay="800"] [data-aos], [data-aos][data-aos][data-aos-delay="800"] {
  transition-delay: 0s;
}

body[data-aos-delay="800"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="800"].aos-animate {
  transition-delay: .8s;
}

body[data-aos-duration="850"] [data-aos], [data-aos][data-aos][data-aos-duration="850"] {
  transition-duration: .85s;
}

body[data-aos-delay="850"] [data-aos], [data-aos][data-aos][data-aos-delay="850"] {
  transition-delay: 0s;
}

body[data-aos-delay="850"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="850"].aos-animate {
  transition-delay: .85s;
}

body[data-aos-duration="900"] [data-aos], [data-aos][data-aos][data-aos-duration="900"] {
  transition-duration: .9s;
}

body[data-aos-delay="900"] [data-aos], [data-aos][data-aos][data-aos-delay="900"] {
  transition-delay: 0s;
}

body[data-aos-delay="900"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="900"].aos-animate {
  transition-delay: .9s;
}

body[data-aos-duration="950"] [data-aos], [data-aos][data-aos][data-aos-duration="950"] {
  transition-duration: .95s;
}

body[data-aos-delay="950"] [data-aos], [data-aos][data-aos][data-aos-delay="950"] {
  transition-delay: 0s;
}

body[data-aos-delay="950"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="950"].aos-animate {
  transition-delay: .95s;
}

body[data-aos-duration="1000"] [data-aos], [data-aos][data-aos][data-aos-duration="1000"] {
  transition-duration: 1s;
}

body[data-aos-delay="1000"] [data-aos], [data-aos][data-aos][data-aos-delay="1000"] {
  transition-delay: 0s;
}

body[data-aos-delay="1000"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1000"].aos-animate {
  transition-delay: 1s;
}

body[data-aos-duration="1050"] [data-aos], [data-aos][data-aos][data-aos-duration="1050"] {
  transition-duration: 1.05s;
}

body[data-aos-delay="1050"] [data-aos], [data-aos][data-aos][data-aos-delay="1050"] {
  transition-delay: 0s;
}

body[data-aos-delay="1050"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1050"].aos-animate {
  transition-delay: 1.05s;
}

body[data-aos-duration="1100"] [data-aos], [data-aos][data-aos][data-aos-duration="1100"] {
  transition-duration: 1.1s;
}

body[data-aos-delay="1100"] [data-aos], [data-aos][data-aos][data-aos-delay="1100"] {
  transition-delay: 0s;
}

body[data-aos-delay="1100"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1100"].aos-animate {
  transition-delay: 1.1s;
}

body[data-aos-duration="1150"] [data-aos], [data-aos][data-aos][data-aos-duration="1150"] {
  transition-duration: 1.15s;
}

body[data-aos-delay="1150"] [data-aos], [data-aos][data-aos][data-aos-delay="1150"] {
  transition-delay: 0s;
}

body[data-aos-delay="1150"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1150"].aos-animate {
  transition-delay: 1.15s;
}

body[data-aos-duration="1200"] [data-aos], [data-aos][data-aos][data-aos-duration="1200"] {
  transition-duration: 1.2s;
}

body[data-aos-delay="1200"] [data-aos], [data-aos][data-aos][data-aos-delay="1200"] {
  transition-delay: 0s;
}

body[data-aos-delay="1200"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1200"].aos-animate {
  transition-delay: 1.2s;
}

body[data-aos-duration="1250"] [data-aos], [data-aos][data-aos][data-aos-duration="1250"] {
  transition-duration: 1.25s;
}

body[data-aos-delay="1250"] [data-aos], [data-aos][data-aos][data-aos-delay="1250"] {
  transition-delay: 0s;
}

body[data-aos-delay="1250"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1250"].aos-animate {
  transition-delay: 1.25s;
}

body[data-aos-duration="1300"] [data-aos], [data-aos][data-aos][data-aos-duration="1300"] {
  transition-duration: 1.3s;
}

body[data-aos-delay="1300"] [data-aos], [data-aos][data-aos][data-aos-delay="1300"] {
  transition-delay: 0s;
}

body[data-aos-delay="1300"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1300"].aos-animate {
  transition-delay: 1.3s;
}

body[data-aos-duration="1350"] [data-aos], [data-aos][data-aos][data-aos-duration="1350"] {
  transition-duration: 1.35s;
}

body[data-aos-delay="1350"] [data-aos], [data-aos][data-aos][data-aos-delay="1350"] {
  transition-delay: 0s;
}

body[data-aos-delay="1350"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1350"].aos-animate {
  transition-delay: 1.35s;
}

body[data-aos-duration="1400"] [data-aos], [data-aos][data-aos][data-aos-duration="1400"] {
  transition-duration: 1.4s;
}

body[data-aos-delay="1400"] [data-aos], [data-aos][data-aos][data-aos-delay="1400"] {
  transition-delay: 0s;
}

body[data-aos-delay="1400"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1400"].aos-animate {
  transition-delay: 1.4s;
}

body[data-aos-duration="1450"] [data-aos], [data-aos][data-aos][data-aos-duration="1450"] {
  transition-duration: 1.45s;
}

body[data-aos-delay="1450"] [data-aos], [data-aos][data-aos][data-aos-delay="1450"] {
  transition-delay: 0s;
}

body[data-aos-delay="1450"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1450"].aos-animate {
  transition-delay: 1.45s;
}

body[data-aos-duration="1500"] [data-aos], [data-aos][data-aos][data-aos-duration="1500"] {
  transition-duration: 1.5s;
}

body[data-aos-delay="1500"] [data-aos], [data-aos][data-aos][data-aos-delay="1500"] {
  transition-delay: 0s;
}

body[data-aos-delay="1500"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1500"].aos-animate {
  transition-delay: 1.5s;
}

body[data-aos-duration="1550"] [data-aos], [data-aos][data-aos][data-aos-duration="1550"] {
  transition-duration: 1.55s;
}

body[data-aos-delay="1550"] [data-aos], [data-aos][data-aos][data-aos-delay="1550"] {
  transition-delay: 0s;
}

body[data-aos-delay="1550"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1550"].aos-animate {
  transition-delay: 1.55s;
}

body[data-aos-duration="1600"] [data-aos], [data-aos][data-aos][data-aos-duration="1600"] {
  transition-duration: 1.6s;
}

body[data-aos-delay="1600"] [data-aos], [data-aos][data-aos][data-aos-delay="1600"] {
  transition-delay: 0s;
}

body[data-aos-delay="1600"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1600"].aos-animate {
  transition-delay: 1.6s;
}

body[data-aos-duration="1650"] [data-aos], [data-aos][data-aos][data-aos-duration="1650"] {
  transition-duration: 1.65s;
}

body[data-aos-delay="1650"] [data-aos], [data-aos][data-aos][data-aos-delay="1650"] {
  transition-delay: 0s;
}

body[data-aos-delay="1650"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1650"].aos-animate {
  transition-delay: 1.65s;
}

body[data-aos-duration="1700"] [data-aos], [data-aos][data-aos][data-aos-duration="1700"] {
  transition-duration: 1.7s;
}

body[data-aos-delay="1700"] [data-aos], [data-aos][data-aos][data-aos-delay="1700"] {
  transition-delay: 0s;
}

body[data-aos-delay="1700"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1700"].aos-animate {
  transition-delay: 1.7s;
}

body[data-aos-duration="1750"] [data-aos], [data-aos][data-aos][data-aos-duration="1750"] {
  transition-duration: 1.75s;
}

body[data-aos-delay="1750"] [data-aos], [data-aos][data-aos][data-aos-delay="1750"] {
  transition-delay: 0s;
}

body[data-aos-delay="1750"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1750"].aos-animate {
  transition-delay: 1.75s;
}

body[data-aos-duration="1800"] [data-aos], [data-aos][data-aos][data-aos-duration="1800"] {
  transition-duration: 1.8s;
}

body[data-aos-delay="1800"] [data-aos], [data-aos][data-aos][data-aos-delay="1800"] {
  transition-delay: 0s;
}

body[data-aos-delay="1800"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1800"].aos-animate {
  transition-delay: 1.8s;
}

body[data-aos-duration="1850"] [data-aos], [data-aos][data-aos][data-aos-duration="1850"] {
  transition-duration: 1.85s;
}

body[data-aos-delay="1850"] [data-aos], [data-aos][data-aos][data-aos-delay="1850"] {
  transition-delay: 0s;
}

body[data-aos-delay="1850"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1850"].aos-animate {
  transition-delay: 1.85s;
}

body[data-aos-duration="1900"] [data-aos], [data-aos][data-aos][data-aos-duration="1900"] {
  transition-duration: 1.9s;
}

body[data-aos-delay="1900"] [data-aos], [data-aos][data-aos][data-aos-delay="1900"] {
  transition-delay: 0s;
}

body[data-aos-delay="1900"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1900"].aos-animate {
  transition-delay: 1.9s;
}

body[data-aos-duration="1950"] [data-aos], [data-aos][data-aos][data-aos-duration="1950"] {
  transition-duration: 1.95s;
}

body[data-aos-delay="1950"] [data-aos], [data-aos][data-aos][data-aos-delay="1950"] {
  transition-delay: 0s;
}

body[data-aos-delay="1950"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1950"].aos-animate {
  transition-delay: 1.95s;
}

body[data-aos-duration="2000"] [data-aos], [data-aos][data-aos][data-aos-duration="2000"] {
  transition-duration: 2s;
}

body[data-aos-delay="2000"] [data-aos], [data-aos][data-aos][data-aos-delay="2000"] {
  transition-delay: 0s;
}

body[data-aos-delay="2000"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2000"].aos-animate {
  transition-delay: 2s;
}

body[data-aos-duration="2050"] [data-aos], [data-aos][data-aos][data-aos-duration="2050"] {
  transition-duration: 2.05s;
}

body[data-aos-delay="2050"] [data-aos], [data-aos][data-aos][data-aos-delay="2050"] {
  transition-delay: 0s;
}

body[data-aos-delay="2050"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2050"].aos-animate {
  transition-delay: 2.05s;
}

body[data-aos-duration="2100"] [data-aos], [data-aos][data-aos][data-aos-duration="2100"] {
  transition-duration: 2.1s;
}

body[data-aos-delay="2100"] [data-aos], [data-aos][data-aos][data-aos-delay="2100"] {
  transition-delay: 0s;
}

body[data-aos-delay="2100"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2100"].aos-animate {
  transition-delay: 2.1s;
}

body[data-aos-duration="2150"] [data-aos], [data-aos][data-aos][data-aos-duration="2150"] {
  transition-duration: 2.15s;
}

body[data-aos-delay="2150"] [data-aos], [data-aos][data-aos][data-aos-delay="2150"] {
  transition-delay: 0s;
}

body[data-aos-delay="2150"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2150"].aos-animate {
  transition-delay: 2.15s;
}

body[data-aos-duration="2200"] [data-aos], [data-aos][data-aos][data-aos-duration="2200"] {
  transition-duration: 2.2s;
}

body[data-aos-delay="2200"] [data-aos], [data-aos][data-aos][data-aos-delay="2200"] {
  transition-delay: 0s;
}

body[data-aos-delay="2200"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2200"].aos-animate {
  transition-delay: 2.2s;
}

body[data-aos-duration="2250"] [data-aos], [data-aos][data-aos][data-aos-duration="2250"] {
  transition-duration: 2.25s;
}

body[data-aos-delay="2250"] [data-aos], [data-aos][data-aos][data-aos-delay="2250"] {
  transition-delay: 0s;
}

body[data-aos-delay="2250"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2250"].aos-animate {
  transition-delay: 2.25s;
}

body[data-aos-duration="2300"] [data-aos], [data-aos][data-aos][data-aos-duration="2300"] {
  transition-duration: 2.3s;
}

body[data-aos-delay="2300"] [data-aos], [data-aos][data-aos][data-aos-delay="2300"] {
  transition-delay: 0s;
}

body[data-aos-delay="2300"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2300"].aos-animate {
  transition-delay: 2.3s;
}

body[data-aos-duration="2350"] [data-aos], [data-aos][data-aos][data-aos-duration="2350"] {
  transition-duration: 2.35s;
}

body[data-aos-delay="2350"] [data-aos], [data-aos][data-aos][data-aos-delay="2350"] {
  transition-delay: 0s;
}

body[data-aos-delay="2350"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2350"].aos-animate {
  transition-delay: 2.35s;
}

body[data-aos-duration="2400"] [data-aos], [data-aos][data-aos][data-aos-duration="2400"] {
  transition-duration: 2.4s;
}

body[data-aos-delay="2400"] [data-aos], [data-aos][data-aos][data-aos-delay="2400"] {
  transition-delay: 0s;
}

body[data-aos-delay="2400"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2400"].aos-animate {
  transition-delay: 2.4s;
}

body[data-aos-duration="2450"] [data-aos], [data-aos][data-aos][data-aos-duration="2450"] {
  transition-duration: 2.45s;
}

body[data-aos-delay="2450"] [data-aos], [data-aos][data-aos][data-aos-delay="2450"] {
  transition-delay: 0s;
}

body[data-aos-delay="2450"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2450"].aos-animate {
  transition-delay: 2.45s;
}

body[data-aos-duration="2500"] [data-aos], [data-aos][data-aos][data-aos-duration="2500"] {
  transition-duration: 2.5s;
}

body[data-aos-delay="2500"] [data-aos], [data-aos][data-aos][data-aos-delay="2500"] {
  transition-delay: 0s;
}

body[data-aos-delay="2500"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2500"].aos-animate {
  transition-delay: 2.5s;
}

body[data-aos-duration="2550"] [data-aos], [data-aos][data-aos][data-aos-duration="2550"] {
  transition-duration: 2.55s;
}

body[data-aos-delay="2550"] [data-aos], [data-aos][data-aos][data-aos-delay="2550"] {
  transition-delay: 0s;
}

body[data-aos-delay="2550"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2550"].aos-animate {
  transition-delay: 2.55s;
}

body[data-aos-duration="2600"] [data-aos], [data-aos][data-aos][data-aos-duration="2600"] {
  transition-duration: 2.6s;
}

body[data-aos-delay="2600"] [data-aos], [data-aos][data-aos][data-aos-delay="2600"] {
  transition-delay: 0s;
}

body[data-aos-delay="2600"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2600"].aos-animate {
  transition-delay: 2.6s;
}

body[data-aos-duration="2650"] [data-aos], [data-aos][data-aos][data-aos-duration="2650"] {
  transition-duration: 2.65s;
}

body[data-aos-delay="2650"] [data-aos], [data-aos][data-aos][data-aos-delay="2650"] {
  transition-delay: 0s;
}

body[data-aos-delay="2650"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2650"].aos-animate {
  transition-delay: 2.65s;
}

body[data-aos-duration="2700"] [data-aos], [data-aos][data-aos][data-aos-duration="2700"] {
  transition-duration: 2.7s;
}

body[data-aos-delay="2700"] [data-aos], [data-aos][data-aos][data-aos-delay="2700"] {
  transition-delay: 0s;
}

body[data-aos-delay="2700"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2700"].aos-animate {
  transition-delay: 2.7s;
}

body[data-aos-duration="2750"] [data-aos], [data-aos][data-aos][data-aos-duration="2750"] {
  transition-duration: 2.75s;
}

body[data-aos-delay="2750"] [data-aos], [data-aos][data-aos][data-aos-delay="2750"] {
  transition-delay: 0s;
}

body[data-aos-delay="2750"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2750"].aos-animate {
  transition-delay: 2.75s;
}

body[data-aos-duration="2800"] [data-aos], [data-aos][data-aos][data-aos-duration="2800"] {
  transition-duration: 2.8s;
}

body[data-aos-delay="2800"] [data-aos], [data-aos][data-aos][data-aos-delay="2800"] {
  transition-delay: 0s;
}

body[data-aos-delay="2800"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2800"].aos-animate {
  transition-delay: 2.8s;
}

body[data-aos-duration="2850"] [data-aos], [data-aos][data-aos][data-aos-duration="2850"] {
  transition-duration: 2.85s;
}

body[data-aos-delay="2850"] [data-aos], [data-aos][data-aos][data-aos-delay="2850"] {
  transition-delay: 0s;
}

body[data-aos-delay="2850"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2850"].aos-animate {
  transition-delay: 2.85s;
}

body[data-aos-duration="2900"] [data-aos], [data-aos][data-aos][data-aos-duration="2900"] {
  transition-duration: 2.9s;
}

body[data-aos-delay="2900"] [data-aos], [data-aos][data-aos][data-aos-delay="2900"] {
  transition-delay: 0s;
}

body[data-aos-delay="2900"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2900"].aos-animate {
  transition-delay: 2.9s;
}

body[data-aos-duration="2950"] [data-aos], [data-aos][data-aos][data-aos-duration="2950"] {
  transition-duration: 2.95s;
}

body[data-aos-delay="2950"] [data-aos], [data-aos][data-aos][data-aos-delay="2950"] {
  transition-delay: 0s;
}

body[data-aos-delay="2950"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2950"].aos-animate {
  transition-delay: 2.95s;
}

body[data-aos-duration="3000"] [data-aos], [data-aos][data-aos][data-aos-duration="3000"] {
  transition-duration: 3s;
}

body[data-aos-delay="3000"] [data-aos], [data-aos][data-aos][data-aos-delay="3000"] {
  transition-delay: 0s;
}

body[data-aos-delay="3000"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="3000"].aos-animate {
  transition-delay: 3s;
}

[data-aos] {
  pointer-events: none;
}

[data-aos].aos-animate {
  pointer-events: auto;
}

body[data-aos-easing="linear"] [data-aos], [data-aos][data-aos][data-aos-easing="linear"] {
  transition-timing-function: cubic-bezier(.25, .25, .75, .75);
}

body[data-aos-easing="ease"] [data-aos], [data-aos][data-aos][data-aos-easing="ease"] {
  transition-timing-function: ease;
}

body[data-aos-easing="ease-in"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in"] {
  transition-timing-function: ease-in;
}

body[data-aos-easing="ease-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out"] {
  transition-timing-function: ease-out;
}

body[data-aos-easing="ease-in-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out"] {
  transition-timing-function: ease-in-out;
}

body[data-aos-easing="ease-in-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-back"] {
  transition-timing-function: cubic-bezier(.6, -.28, .735, .045);
}

body[data-aos-easing="ease-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-back"] {
  transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
}

body[data-aos-easing="ease-in-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-back"] {
  transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
}

body[data-aos-easing="ease-in-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-sine"] {
  transition-timing-function: cubic-bezier(.47, 0, .745, .715);
}

body[data-aos-easing="ease-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-sine"] {
  transition-timing-function: cubic-bezier(.39, .575, .565, 1);
}

body[data-aos-easing="ease-in-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-sine"] {
  transition-timing-function: cubic-bezier(.445, .05, .55, .95);
}

body[data-aos-easing="ease-in-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quad"] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

body[data-aos-easing="ease-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quad"] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

body[data-aos-easing="ease-in-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quad"] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

body[data-aos-easing="ease-in-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-cubic"] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

body[data-aos-easing="ease-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-cubic"] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

body[data-aos-easing="ease-in-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-cubic"] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

body[data-aos-easing="ease-in-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quart"] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

body[data-aos-easing="ease-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quart"] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

body[data-aos-easing="ease-in-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quart"] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

@media screen {
  html:not(.no-js) [data-aos^="fade"][data-aos^="fade"] {
    opacity: 0;
    transition-property: opacity, transform;
    transition-duration: .25s, .9s !important;
  }

  html:not(.no-js) [data-aos^="fade"][data-aos^="fade"].aos-animate {
    opacity: 1;
    transform: none;
  }

  html:not(.no-js) [data-aos="fade-up"] {
    transform: translate3d(0, 10px, 0);
  }

  html:not(.no-js) [data-aos="fade-down"] {
    transform: translate3d(0, -10px, 0);
  }

  html:not(.no-js) [data-aos="fade-right"] {
    transform: translate3d(-10px, 0, 0);
  }

  html:not(.no-js) [data-aos="fade-left"] {
    transform: translate3d(10px, 0, 0);
  }

  html:not(.no-js) [data-aos="fade-up-right"] {
    transform: translate3d(-10px, 10px, 0);
  }

  html:not(.no-js) [data-aos="fade-up-left"] {
    transform: translate3d(10px, 10px, 0);
  }

  html:not(.no-js) [data-aos="fade-down-right"] {
    transform: translate3d(-10px, -10px, 0);
  }

  html:not(.no-js) [data-aos="fade-down-left"] {
    transform: translate3d(10px, -10px, 0);
  }

  html:not(.no-js) [data-aos^="zoom"][data-aos^="zoom"] {
    opacity: 0;
    transition-property: opacity, transform;
  }

  html:not(.no-js) [data-aos^="zoom"][data-aos^="zoom"].aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0)scale(1);
  }

  html:not(.no-js) [data-aos="zoom-in"] {
    transform: scale(.6);
  }

  html:not(.no-js) [data-aos="zoom-in-up"] {
    transform: translate3d(0, 10px, 0)scale(.6);
  }

  html:not(.no-js) [data-aos="zoom-in-down"] {
    transform: translate3d(0, -10px, 0)scale(.6);
  }

  html:not(.no-js) [data-aos="zoom-in-right"] {
    transform: translate3d(-10px, 0, 0)scale(.6);
  }

  html:not(.no-js) [data-aos="zoom-in-left"] {
    transform: translate3d(10px, 0, 0)scale(.6);
  }

  html:not(.no-js) [data-aos="zoom-out"] {
    transform: scale(1.2);
  }

  html:not(.no-js) [data-aos="zoom-out-up"] {
    transform: translate3d(0, 10px, 0)scale(1.2);
  }

  html:not(.no-js) [data-aos="zoom-out-down"] {
    transform: translate3d(0, -10px, 0)scale(1.2);
  }

  html:not(.no-js) [data-aos="zoom-out-right"] {
    transform: translate3d(-10px, 0, 0)scale(1.2);
  }

  html:not(.no-js) [data-aos="zoom-out-left"] {
    transform: translate3d(10px, 0, 0)scale(1.2);
  }

  html:not(.no-js) [data-aos^="slide"][data-aos^="slide"] {
    visibility: hidden;
    transition-property: transform;
  }

  html:not(.no-js) [data-aos^="slide"][data-aos^="slide"].aos-animate {
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }

  html:not(.no-js) [data-aos="slide-up"] {
    transform: translate3d(0, 100%, 0);
  }

  html:not(.no-js) [data-aos="slide-down"] {
    transform: translate3d(0, -100%, 0);
  }

  html:not(.no-js) [data-aos="slide-right"] {
    transform: translate3d(-100%, 0, 0);
  }

  html:not(.no-js) [data-aos="slide-left"] {
    transform: translate3d(100%, 0, 0);
  }

  html:not(.no-js) [data-aos^="flip"][data-aos^="flip"] {
    backface-visibility: hidden;
    transition-property: transform;
  }

  html:not(.no-js) [data-aos="flip-left"] {
    transform: perspective(2500px)rotateY(-100deg);
  }

  html:not(.no-js) [data-aos="flip-left"].aos-animate {
    transform: perspective(2500px)rotateY(0);
  }

  html:not(.no-js) [data-aos="flip-right"] {
    transform: perspective(2500px)rotateY(100deg);
  }

  html:not(.no-js) [data-aos="flip-right"].aos-animate {
    transform: perspective(2500px)rotateY(0);
  }

  html:not(.no-js) [data-aos="flip-up"] {
    transform: perspective(2500px)rotateX(-100deg);
  }

  html:not(.no-js) [data-aos="flip-up"].aos-animate {
    transform: perspective(2500px)rotateX(0);
  }

  html:not(.no-js) [data-aos="flip-down"] {
    transform: perspective(2500px)rotateX(100deg);
  }

  html:not(.no-js) [data-aos="flip-down"].aos-animate {
    transform: perspective(2500px)rotateX(0);
  }
}

:root {
  --xl-device: 1920px;
  --lg-device: 1440px;
  --md-device: 1024px;
  --md2-device: 768px;
  --sm-device: 600px;
}

@media (width <= 768px) {
  * p {
    font-size: 12px !important;
  }

  * h2 {
    font-size: 14px !important;
  }

  #conteudo-principal .nome-projeto h2 {
    font-size: 16px !important;
  }

  #conteudo-principal .equipe-wrapper .conteudo-equipe p {
    font-style: italic;
    font-size: 14px !important;
  }

  #conteudo-principal .equipe-wrapper h2 {
    font-size: 16px !important;
  }
}

.mt-300 {
  margin-top: 300px;
}

.mt-250 {
  margin-top: 250px;
}

.mySwiper1, .mySwiper2, .mySwiper3, .mySwiper4 {
  margin-top: 300px;
}

.mySwiper4 img {
  max-height: 700px;
}

.mySwiper3x {
  overflow: initial;
  margin-top: 300px;
}

.mySwiper3x .swiper-wrapper {
  max-height: 95vh;
}

@media screen and (width <= 768px) {
  .mySwiper3x .swiper-wrapper {
    max-height: initial !important;
    max-width: 100% !important;
    height: auto !important;
  }
}

.mySwiper3x .swiper-slide {
  width: initial;
}

@media screen and (width <= 768px) {
  .mySwiper3x .swiper-slide {
    width: 100%;
  }
}

.mySwiper3x .swiper-slide img {
  max-height: 95vh;
  max-width: initial;
  width: auto;
  height: 100% !important;
}

@media screen and (width <= 768px) {
  .mySwiper3x .swiper-slide img {
    max-height: initial !important;
    max-width: 100% !important;
    height: auto !important;
  }
}

.swiper-btns {
  z-index: 10;
  justify-content: space-between;
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 50%;
}

.swiper-btns .swiper-button-next:after, .swiper-btns .swiper-button-prev:after {
  display: none;
}

.swiper-btns .swiper-button-next {
  transform: rotate(90deg)translateY(5px);
}

.swiper-btns .swiper-button-prev {
  transform: rotate(-90deg)translateY(5px);
}

.swiper-btns .swiper-button-next, .swiper-btns .swiper-button-prev {
  z-index: 30;
  cursor: pointer;
  width: 24px;
  height: 24px;
  top: unset;
  transition: all .3s;
  bottom: 0;
}

@media screen and (width <= 1024px) {
  .swiper-btns .swiper-button-next, .swiper-btns .swiper-button-prev {
    width: 14px !important;
  }
}

.swiper-btns .swiper-button-next:hover {
  transform: rotate(90deg)translateY(0);
}

.swiper-btns .swiper-button-prev:hover {
  transform: rotate(-90deg)translateY(0);
}

.swiper-btns .swiper-button-disabled {
  opacity: 0 !important;
}

.mySwiper5, .mySwiper6 {
  margin-top: 300px;
}

img {
  max-width: auto;
  height: auto;
}

.img-1 {
  justify-content: flex-end;
  margin-bottom: 300px;
  display: flex;
}

.img-wrapper {
  gap: 300px;
  margin-bottom: 200px;
  display: grid;
}

.img-dupla {
  align-items: flex-end;
  gap: 25px;
  margin-top: 300px;
  display: flex;
}

.img-dupla img {
  margin: 0 auto;
  max-width: 598px !important;
}

.img-central img {
  margin: 300px auto 0;
}

.img-central {
  margin: 0 auto;
}

.img-unica {
  margin-top: 300px;
}

.img-ultima {
  margin-bottom: 300px;
}

.img-unica-esquerda {
  margin-top: 300px;
}

.img-unica-direita {
  justify-content: flex-end;
  margin-top: 300px;
  display: flex;
}

.tres-imgs-wrapper {
  grid-template-columns: 1fr 1fr;
  margin-top: 300px;
  display: grid;
}

.texto-info {
  gap: 25px;
  max-width: 741px;
  margin: 300px auto 0;
  display: grid;
}

.texto-info h3 {
  color: var(--cor-azul-1);
  margin-bottom: 40px;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
}

.texto-info .texto-container {
  gap: 20px;
  display: grid;
}

.texto-info .texto-container p {
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
}

.img-dupla-2 {
  justify-content: space-between;
  display: flex;
}

.img-dupla-2 img {
  max-width: 50%;
  height: auto;
  margin: 0 auto;
}

@media (width <= 1870px) {
  .img-dupla {
    flex-wrap: wrap;
  }
}

@media (width <= 1100px) {
  .tres-imgs-wrapper {
    grid-template-columns: 1fr;
    gap: 50px;
    margin-top: 300px;
    display: grid;
  }

  .tres-imgs-wrapper img, .tres-imgs-wrapper .wrapper-tres img {
    margin: 0 auto;
  }
}

@media (width <= 876px) {
  .mobile-0e1-logo {
    width: 88px;
  }

  .mySwiper3x {
    margin-top: 100px !important;
  }

  .mySwiper3x .swiper-slide {
    height: auto !important;
  }

  .mySwiper3x .swiper-slide img {
    height: 100% !important;
  }

  .mySwiper1 {
    margin-top: 100px;
  }

  .mySwiper1 .swiper-slide {
    width: initial;
  }

  .mySwiper1 .swiper-slide img {
    max-width: initial;
    width: auto;
    height: 100% !important;
  }

  .mySwiper2 {
    margin-top: 100px;
  }

  .mySwiper2 .swiper-slide {
    width: initial;
  }

  .mySwiper2 .swiper-slide img {
    max-width: initial;
    width: auto;
    height: 100% !important;
  }

  .mySwiper3 {
    margin-top: 100px;
  }

  .mySwiper3 .swiper-slide {
    width: initial;
  }

  .mySwiper3 .swiper-slide img {
    max-width: initial;
    width: auto;
    height: 100% !important;
  }

  .mySwiper4 {
    margin-top: 100px;
  }

  .mySwiper4 .swiper-slide img {
    width: auto;
    height: 100% !important;
  }

  .texto-info {
    margin-top: 100px;
  }

  .img-1 {
    margin-bottom: 100px;
  }

  .img-wrapper {
    gap: 100px;
    margin-bottom: 100px;
  }

  .img-dupla {
    align-items: flex-end;
    gap: 25px;
    margin-top: 100px;
    display: flex;
  }

  .img-dupla img {
    margin: 0 auto;
    max-width: 100% !important;
  }

  .mySwiper5 {
    margin-top: 100px;
  }

  .mySwiper5 .swiper-slide {
    width: initial;
  }

  .mySwiper5 .swiper-slide img {
    max-width: initial;
    width: auto;
    height: 100% !important;
  }

  .mySwiper6 {
    margin-top: 100px;
  }

  .mySwiper6 .swiper-slide img {
    width: auto;
  }

  .tres-imgs-wrapper {
    margin-top: 100px;
  }

  .img-central img {
    margin: 100px auto 0;
  }

  .img-central {
    margin: 0 auto;
  }

  .img-unica {
    margin-top: 100px;
  }

  .img-ultima {
    margin-bottom: 100px;
  }

  .img-unica-esquerda, .img-unica-direita {
    margin-top: 100px;
  }

  .img-dupla {
    flex-wrap: wrap;
    gap: 50px;
  }

  .img-dupla img {
    max-width: 100%;
  }
}

#conteudo-principal .equipe-wrapper .conteudo-equipe, #conteudo-principal .equipe-wrapper.info-final .conteudo-equipe {
  text-align: start !important;
  flex-wrap: wrap !important;
  align-items: baseline !important;
}

@media screen and (width <= 1024px) {
  .nome-projeto .\!mt-\[55px\] {
    flex-direction: column;
    margin-top: 32px !important;
  }

  #conteudo-principal .texto-wrapper {
    margin-top: 32px !important;
  }

  #conteudo-principal .texto-wrapper .texto {
    margin-top: 0 !important;
  }
}

.voltar-page h2 {
  color: var(--cor-azul-1);
  justify-content: flex-end;
  width: max-content;
  padding-right: 50px;
  font-family: Exo;
  font-size: 32px;
  font-style: italic;
  font-weight: 500;
  line-height: 43px;
  transition: all .2s;
  display: flex;
  margin-left: auto !important;
}

@media screen and (width <= 1024px) {
  .voltar-page h2 {
    padding-right: 0;
  }
}

.voltar-page h2:hover {
  transform: translateX(-5px);
}

#conteudo-principal .info-final .container-conteudo {
  max-width: max-content !important;
}

.swiper-wrapper {
  -webkit-user-select: none;
  user-select: none;
}

.mais-projetos-wrapper {
  display: block !important;
}

.mais-projetos-wrapper h2 {
  width: max-content;
  display: inline-flex;
}

.mais-projetos-wrapper .swiper {
  margin-top: 4rem !important;
}

.mais-projetos-wrapper .swiper .img-wrapper {
  gap: 0 !important;
  margin-bottom: 0 !important;
}

.mais-projetos-wrapper .swiper-slide {
  width: 350px;
}

@media screen and (width <= 768px) {
  .mais-projetos-wrapper .swiper-slide {
    width: 90%;
    max-width: 350px;
  }
}

.mais-projetos-wrapper .swiper-slide .projeto-wrapper {
  cursor: pointer;
  height: 100%;
}

.mais-projetos-wrapper .swiper-slide .projeto-wrapper .img-wrapper {
  --size: clamp(180px, 30vh, 17.6875rem);
  width: 100%;
  height: 100%;
  height: var(--size);
  position: relative;
  overflow: hidden;
  display: block !important;
}

.mais-projetos-wrapper .swiper-slide .projeto-wrapper:hover img {
  transform: scale(1.1);
}

.mais-projetos-wrapper .swiper-slide .projeto-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  margin: auto;
  transition: all .5s ease-out;
  position: absolute;
  inset: 0;
}

.mais-projetos-wrapper .swiper-slide .projeto-wrapper .descricao-projeto {
  border-bottom: 2px solid var(--cor-azul-1);
  color: var(--cor-azul-1);
  justify-content: space-between;
  padding: 9px 0 6px;
  font-family: Exo;
  transition: all .3s;
  display: flex;
}

.mais-projetos-wrapper .swiper-slide .projeto-wrapper .descricao-projeto p {
  transition: all .3s;
}

.mais-projetos-wrapper .swiper-slide .projeto-wrapper:hover .descricao-projeto {
  border-bottom: 2px solid var(--cor-roxo-1);
  color: var(--cor-roxo-1);
  transition: all .3s;
}

.mais-projetos-wrapper .swiper-slide .projeto-wrapper:hover .descricao-projeto p {
  transition: all .3s;
}

.btn-show {
  width: max-content;
  transition: transform .2s;
}

.btn-show .icon-container {
  border-radius: 16px;
  flex-shrink: 0;
  align-items: center;
  width: 52px;
  height: 52px;
  padding: 8px;
  transition: all .4s cubic-bezier(.65, 0, .35, 1);
  display: flex;
  overflow: hidden;
  background-color: #fff !important;
}

.btn-show .icon-container .fale-wrapper {
  flex-shrink: 0;
  width: max-content;
  overflow: hidden;
}

.btn-show .icon-container svg {
  --cor1: #5a5a5a;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
}

.btn-show .icon-container p {
  color: var(--cor-branco-1);
  white-space: nowrap;
  padding-left: 8px;
  font-family: Exo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  transition: all .3s cubic-bezier(.65, 0, .35, 1) .2s;
  transform: translateX(-120px);
}

.btn-show:hover .icon-container {
  width: 180px;
  background-color: #3054ff !important;
}

.btn-show:hover .icon-container p {
  z-index: 1;
  transform: translateX(0);
}

.btn-show:hover svg {
  --cor1: white;
}

.btn-show:active {
  transform: scale(1.05);
}

.menu-lateral {
  width: 234px;
}

.menu-lateral .x-menu {
  justify-content: end;
  display: flex;
}

.menu-lateral .menu-lista {
  margin-top: 41px;
}

.menu-lateral .menu-lista li {
  text-align: right;
  padding: 20px 0 20px 20px;
}

.menu-lateral .menu-lista li a {
  color: var(--cor-azul-1);
  font-family: Exo;
  font-size: 1.5rem;
  font-weight: 500;
}

.menu-lateral .menu-lista li a:hover {
  color: var(--cor-roxo-1);
  transition: all .2s;
}

@keyframes volta_menu {
  to {
    clip-path: circle(0% at 80% 0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }
}

@keyframes opacidade_1 {
  to {
    opacity: 1;
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

body {
  opacity: 0;
  font-family: Exo, sans-serif;
  font-size: 1rem;
  overflow-x: hidden;
}

body.dcl {
  opacity: 1;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 1.5rem;
  position: relative;
}

@media (width >= 576px) {
  .container {
    max-width: 540px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 720px;
  }
}

@media (width >= 992px) {
  .container {
    max-width: 960px;
  }
}

@media (width >= 1200px) {
  .container {
    max-width: 1100px;
  }
}

@media (width >= 1400px) {
  .container {
    max-width: calc(1216px + 3rem);
  }
}

html {
  --font-base: 100%;
  font-size: var(--font-base);
  overflow-x: hidden;
}

@media (width <= 1440px) {
  html {
    font-size: calc(var(--font-base)  - 9.25%);
  }
}

@media (width <= 980px) {
  html {
    font-size: calc(var(--font-base)  - 12.5%);
  }
}

body:not(.open-menu) .menu-lateral {
  pointer-events: none;
}

@media (width <= 1024px) {
  body:not(.open-menu) .menu-lateral {
    pointer-events: all;
  }
}

#menu {
  z-index: 1000;
  position: fixed;
  top: 56px;
  right: 60px;
}

#menu #hamburger {
  color: #fff;
  border-top: 3px solid;
  width: 40px;
  display: block;
}

#menu #hamburger:after, #menu #hamburger:before {
  content: "";
  background-color: currentColor;
  width: 40px;
  height: 3px;
  margin-top: 7px;
  transition: all .3s;
  display: block;
  position: relative;
}

.botao-fale-conosco {
  z-index: 10;
  color: #fff;
  position: fixed;
  top: 80px;
  left: 80px;
}

.botao-fale-conosco .fale-conosco {
  opacity: 0;
  width: 168px;
  height: 41px;
  transition: all .3s;
  position: absolute;
  top: 0;
}

.botao-fale-conosco:hover .fale-conosco {
  opacity: 1;
  transition: all .3s;
  position: absolute;
}

.navbar {
  z-index: 100;
  pointer-events: all;
  justify-content: flex-start;
  align-items: center;
  height: 70px;
  display: flex;
}

.menu-toggle {
  cursor: pointer;
  background-color: #0000;
  border: none;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  display: flex;
}

.menu-icon {
  border-top: 3px solid var(--cor-azul-1);
  width: 40px;
  display: block;
}

.menu-icon:after, .menu-icon:before {
  content: "";
  background-color: var(--cor-azul-1);
  width: 40px;
  height: 3px;
  margin-top: 5px;
  transition: all .3s;
  display: block;
  position: relative;
}

.menu-icon:before, .menu-icon:after {
  right: 0;
}

.menu {
  pointer-events: none;
  width: 200px;
  height: 30vh;
  margin: 37px 0 0;
  padding: 20px 50px 20px 20px;
  transition: transform .3s ease-in-out;
  position: fixed;
  top: 100px;
  right: -200px;
}

.menu li {
  text-align: right;
  padding-top: 20px;
  padding-right: 0;
}

.menu li a {
  color: var(--cor-azul-1);
  font-family: Exo;
  font-size: 1.5rem;
  font-weight: 500;
}

.menu li a:hover {
  color: var(--cor-roxo-1);
  transition: all .2s;
}

.menu-toggle.open .menu-icon {
  color: var(--cor-azul-1);
  border-top-color: #0000;
  transform: rotate(0);
}

.menu-toggle.open .menu-icon:before {
  transform: rotate(135deg);
}

.menu-toggle.open .menu-icon:after {
  top: -7px;
  transform: rotate(-135deg);
}

.menu.open {
  pointer-events: all;
  transform: translateX(-200px);
}

body.page__contato .menu-icon {
  border-top: 3px solid var(--cor-branco-1);
}

body.page__contato .menu-icon:after, body.page__contato .menu-icon:before {
  background-color: var(--cor-branco-1);
}

body.page__contato .menu {
  top: 100px;
}

body.page__contato .menu li a {
  color: var(--cor-branco-1);
}

body.page__contato .menu li a:hover {
  color: var(--cor-roxo-1);
  transition: all .2s;
}

body.page__contato .menu-toggle.open .menu-icon {
  color: var(--cor-azul-1);
}

body.page__home .menu-icon {
  border-top: 3px solid var(--cor-branco-1);
}

body.page__home .menu-icon:after, body.page__home .menu-icon:before {
  background-color: var(--cor-branco-1);
}

body.page__home .menu li a {
  color: var(--cor-branco-1);
}

body.page__home .menu li a:hover {
  color: var(--cor-roxo-1);
  transition: all .2s;
}

body.page__home .menu-toggle.open .menu-icon {
  color: var(--cor-azul-1);
}

@media (width <= 768px) {
  .menu-icon {
    border-top: 2px solid var(--cor-azul-1);
    width: 25px;
    display: block;
  }

  .menu-icon:after, .menu-icon:before {
    content: "";
    background-color: var(--cor-azul-1);
    width: 25px;
    height: 2px;
    margin-top: 5px;
    transition: all .3s;
    display: block;
    position: relative;
  }

  #menu {
    z-index: 10;
    position: fixed;
    top: 50px;
    right: 50px;
  }

  #menu #hamburger {
    color: #fff;
    border-top: 3px solid;
    width: 40px;
    display: block;
  }

  #menu #hamburger:after, #menu #hamburger:before {
    content: "";
    background-color: currentColor;
    width: 40px;
    height: 3px;
    margin-top: 7px;
    transition: all .3s;
    display: block;
    position: relative;
  }

  .botao-fale-conosco {
    top: 60px;
    left: 50px;
  }

  .img-branco.open {
    filter: brightness(0) saturate() invert(26%) sepia(44%) saturate(5875%) hue-rotate(228deg) brightness() contrast(103%);
    transition: all 0s ease-in-out .3s;
  }

  .navbar {
    justify-content: flex-end;
    align-items: center;
    height: 70px;
    display: flex;
    right: 20px !important;
  }

  .hamburger-menu {
    z-index: 10;
  }

  .menu {
    background: var(--cor-branco-2);
    height: 100%;
    margin: 0;
    padding: 20px 30px 20px 20px;
    transition: transform .3s ease-in-out;
    position: fixed;
    padding-top: 60px !important;
    top: 0 !important;
    right: -420px !important;
  }

  .menu li a {
    color: var(--cor-azul-1) !important;
  }

  .menu.open {
    transform: translateX(-420px);
    width: 100% !important;
  }

  .menu-toggle.open .menu-icon:after, .menu-toggle.open .menu-icon:before {
    background-color: var(--cor-azul-1) !important;
  }

  #page__contato .menu {
    padding-top: 80px !important;
  }
}

.menu-projetos {
  z-index: 999999999;
  position: fixed;
  top: 70px;
  right: -100px;
}

.menu-projetos .menu {
  top: 120px;
  right: -150px;
}

.logos-menu-mobile {
  justify-content: center;
  gap: 35px;
  width: 100%;
  padding-right: 40px;
  display: none;
  position: absolute;
  bottom: 20px;
}

.fancybox__container {
  z-index: 2147483647 !important;
}

@media (width <= 768px) {
  .logos-menu-mobile {
    display: flex;
  }

  .logo-projeto {
    display: none;
  }

  .menu-projetos {
    z-index: 9;
    background: #fff;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    height: unset !important;
  }

  .menu-projetos .zero-e-um-img {
    z-index: 999;
    position: relative;
  }

  .menu-projetos .zero-e-um-img img {
    display: block;
  }

  #page__home .navbar {
    height: 50px;
  }
}

.footer-wrapper {
  justify-content: space-between;
  display: flex;
}

.footer-bottom {
  margin-top: 32px;
}

.footer-bottom .footer-wrapper {
  background: var(--cor-branco-1);
  justify-content: space-between;
  width: 100%;
  padding: 1rem 0 3.1rem;
  display: flex;
  position: static;
  bottom: 30px;
  left: 0;
}

#login {
  --hue-primary: 230;
  --white-10-op: #ffffff1a;
  --white: #fff;
  --neutral-10: #f5f6fa;
  --primary-light: #8186f7;
  --primary-light-10-op: #8288f71a;
  --white-70-op: #ffffffb3;
  --primary-pure: hsla(var(--hue-primary), 100%, 59%, 1);
  background: linear-gradient(90deg, hsla(var(--hue-primary), 100%, 3%, 1) 0%, hsla(var(--hue-primary), 100%, 3%, 1) 100%);
  height: 100vh;
  padding-top: 3.75rem;
  position: relative;
}

@media (width <= 1660px) {
  #login {
    height: 120vh;
  }
}

@media screen and (width <= 1024px) {
  #login {
    height: 100vh;
  }
}

@media screen and (width <= 600px) {
  #login {
    padding: unset;
  }
}

@media (width <= 400px) {
  #login {
    height: 120vh;
  }
}

#login .ornamento {
  background: linear-gradient(92.74deg, hsla(var(--hue-primary), 68%, 32%, 1) 0%, hsla(var(--hue-primary), 100%, 73%, 1) 100%);
  border-radius: 11.875rem;
  width: 100%;
  max-width: 1800px;
  height: 100%;
  max-height: 394px;
  margin: 0 auto;
}

@media (width <= 1660px) {
  #login .ornamento {
    width: 90%;
    padding: 3rem;
  }
}

@media screen and (width <= 1440px) {
  #login .ornamento {
    height: 40%;
  }
}

@media screen and (width <= 600px) {
  #login .ornamento {
    width: 100%;
    height: 349px;
    margin: unset;
    border-radius: 0 0 16px 16px;
  }
}

#login .ornamento .logo-cliente {
  width: fit-content;
  margin: 0 auto;
  padding-top: 4.6875rem;
}

@media (width <= 1660px) {
  #login .ornamento .logo-cliente {
    padding-top: 2.75rem;
  }
}

@media screen and (width <= 1440px) {
  #login .ornamento .logo-cliente {
    padding-top: 2rem;
  }
}

#login .orn-sections {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

#login .orn-sections img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

#login .logo-ndt {
  position: absolute;
  bottom: 72px;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (width <= 1440px) {
  #login .logo-ndt {
    bottom: 64px;
  }
}

#login .form-wrapper {
  border: 1px solid var(--white-10-op);
  backdrop-filter: blur(40px);
  background: #00000080;
  border-radius: 8px;
  width: 100%;
  max-width: 428px;
  height: fit-content;
  margin: -210px auto 0;
  padding: 2rem;
}

@media screen and (width <= 1440px) {
  #login .form-wrapper {
    margin-top: -150px;
  }
}

@media screen and (width <= 600px) {
  #login .form-wrapper {
    width: 327px;
  }
}

#login .form-wrapper .content .title {
  letter-spacing: -.02em;
  color: var(--white);
  margin-bottom: .5rem;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 133%;
}

#login .form-wrapper .content .description {
  letter-spacing: -.02em;
  color: var(--white-70-op);
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
}

#login .form-wrapper .inputs-wrapper {
  margin-top: 2rem;
}

#login .form-wrapper .inputs-wrapper .input-item:first-child {
  margin-bottom: 1.5rem;
}

#login .form-wrapper .inputs-wrapper .input-item .input {
  border: unset;
  letter-spacing: -.02em;
  width: 100%;
  height: 56px;
  color: var(--white);
  background: none;
  border-bottom: 1px solid #ffffff1a;
  border-radius: 4px;
  padding: .5rem;
  font-family: Exo, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
}

@media screen and (width <= 600px) {
  #login .form-wrapper .inputs-wrapper .input-item .input {
    font-size: .875rem;
    line-height: 171%;
  }
}

#login .form-wrapper .inputs-wrapper .input-item .input::placeholder {
  letter-spacing: -.02em;
  color: var(--white);
  font-family: Exo, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
}

@media screen and (width <= 600px) {
  #login .form-wrapper .inputs-wrapper .input-item .input::placeholder {
    font-size: .875rem;
    line-height: 171%;
  }
}

#login .form-wrapper .inputs-wrapper .input-item .show-password {
  color: var(--white-70-op);
  cursor: pointer;
  display: flex;
  position: relative;
}

#login .form-wrapper .inputs-wrapper .input-item .show-password .password {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  right: .5rem;
  transform: translateY(-50%);
}

#login .form-wrapper .inputs-wrapper .input-item .show-password .password svg {
  width: 24px;
  height: 24px;
}

#login .form-wrapper .inputs-wrapper .input-item .show-password .password .hidden {
  display: none;
}

#login .form-wrapper .inputs-wrapper .input-item .show-password .password.mostra .hidden {
  display: block;
}

#login .form-wrapper .inputs-wrapper .input-item .show-password .password.mostra .show {
  display: none;
}

#login .form-wrapper .inputs-wrapper .recuperar-senha {
  margin-top: .5rem;
}

#login .form-wrapper .inputs-wrapper .recuperar-senha a {
  letter-spacing: -.02em;
  color: var(--white-70-op);
  font-size: .75rem;
  font-weight: 300;
  line-height: 167%;
  text-decoration: none;
}

#login .form-wrapper .inputs-wrapper .recuperar-senha a:hover {
  color: var(--white);
  text-decoration: underline;
}

#login .form-wrapper .btn-wrapper {
  margin-top: 3.5rem;
}

#login .form-wrapper .btn-wrapper .btn {
  letter-spacing: -.02em;
  background-color: var(--primary-light-10-op);
  border: 1px solid var(--primary-light);
  width: 100%;
  height: 56px;
  color: var(--primary-light);
  cursor: pointer;
  border-radius: 1.75rem;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 150%;
  transition: background-color .2s ease-in, transform .1s ease-in;
  display: flex;
}

@media screen and (width <= 600px) {
  #login .form-wrapper .btn-wrapper .btn {
    padding: .5rem 2rem;
    font-size: .875rem;
  }
}

#login .form-wrapper .btn-wrapper .btn:hover {
  box-shadow: 0 .5em .5em -.4em var(--primary-pure);
  color: var(--neutral-10);
  border-color: var(--primary-pure);
  transform: translateY(-.25em) !important;
}

#login .form-wrapper .voltar {
  width: fit-content;
  margin: 1rem auto 0;
}

#login .form-wrapper .voltar a {
  letter-spacing: -.02em;
  color: var(--white-70-op);
  font-size: .875rem;
  font-weight: 300;
  line-height: 157%;
}

#login .form-wrapper .voltar a:hover {
  color: var(--white);
  text-decoration: underline;
}

#login .form-wrapper .checkbox-wrapper {
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  display: flex;
}

#login .form-wrapper .checkbox-wrapper .radio-container {
  align-items: center;
  display: flex;
}

#login .form-wrapper .checkbox-wrapper .radio-container .check-label {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  letter-spacing: -.02em;
  color: var(--white-70-op);
  align-items: center;
  gap: .375rem;
  font-size: .875rem;
  font-weight: 300;
  line-height: 150%;
  display: inline-flex;
}

#login .form-wrapper .checkbox-wrapper .radio-container .check-label .checkbox {
  clip: rect(0px, 0px, 0px, 0px);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

#login .form-wrapper .checkbox-wrapper .radio-container .check-label:hover {
  color: var(--white);
  font-weight: 400;
}

#login .form-wrapper .checkbox-wrapper .radio-container .check-label:hover .radio-circle {
  border-color: var(--primary-pure);
  border-width: 2px;
}

#login .form-wrapper .checkbox-wrapper .radio-container .radio-circle {
  border: 1px solid #fff3;
  border-radius: 50%;
  flex-shrink: 0;
  align-items: center;
  width: 20px;
  height: 20px;
  display: inline-flex;
  position: relative;
}

#login .form-wrapper .checkbox-wrapper .radio-container .radio-circle-inner {
  background: var(--primary-pure);
  width: 0;
  height: 0;
  transition: all .2s ease-out;
  transition-property: width height;
  border-radius: 50%;
  flex-shrink: 0;
  margin: auto;
  position: absolute;
  inset: 0;
}

#login .form-wrapper .checkbox-wrapper .radio-container input:checked ~ span {
  border-color: var(--primary-pure);
}

#login .form-wrapper .checkbox-wrapper .radio-container input:checked ~ span i {
  width: 12px;
  height: 12px;
}

#login .form-wrapper .checkbox-wrapper .radio-container.round .radio-circle {
  border-radius: .25rem;
}

#login .form-wrapper .checkbox-wrapper .radio-container.round .radio-circle-inner {
  transition: initial;
  border-radius: initial !important;
}

#login .form-wrapper .checkbox-wrapper .radio-container.round input:checked ~ span i {
  border-radius: initial;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxOSAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3IDJMNy42MjY4MSAxMkwyIDUuOTk2OTIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: auto;
}

#login .form-wrapper .checkbox-wrapper .radio-container.disabled {
  -webkit-user-select: none;
  user-select: none;
}

#login .form-wrapper .checkbox-wrapper .radio-container.disabled .check-label {
  pointer-events: none;
  color: var(--neutral-60);
}

#login .form-wrapper .checkbox-wrapper .radio-container.disabled .radio-circle {
  pointer-events: none;
  opacity: .2;
}

.img-principal {
  position: relative;
  overflow: hidden;
}

.img-principal > img {
  object-fit: cover !important;
  width: 100% !important;
  height: calc(100vh - 100px) !important;
}

.img-principal .logo {
  position: absolute;
  top: 50px;
  left: 50px;
}

#conteudo-principal .nome-projeto {
  border-bottom: 2px solid var(--cor-azul-1);
  justify-content: flex-start;
  display: flex;
}

#conteudo-principal .nome-projeto h2 {
  color: var(--cor-azul-1);
  border-right: 2px solid var(--cor-azul-1);
  margin-top: 55px;
  padding-right: 50px;
  font-family: Exo;
  font-size: 32px;
  font-style: italic;
  font-weight: 500;
  line-height: 43px;
}

@media screen and (width <= 1024px) {
  #conteudo-principal .nome-projeto {
    flex-direction: column;
  }

  #conteudo-principal .nome-projeto h2 {
    border: none !important;
  }

  #conteudo-principal .nome-projeto p {
    margin-left: initial;
    border: none !important;
    padding: 0 !important;
  }
}

#conteudo-principal .container-conteudo {
  max-width: 759px;
  margin: 0 auto;
}

#conteudo-principal .texto-wrapper {
  margin-top: 84px;
}

#conteudo-principal .texto-wrapper h2 {
  color: var(--cor-azul-1);
  font-family: Exo;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 43px;
}

#conteudo-principal .texto-wrapper .texto {
  gap: 20px;
  display: grid;
}

#conteudo-principal .texto-wrapper .texto p {
  color: var(--cor-preto-1);
  font-family: Exo;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
}

#conteudo-principal .info-wrapper {
  border-top: 2px solid var(--cor-azul-1);
  justify-content: space-between;
  margin-top: 84px;
  margin-bottom: 291.4px;
  display: flex;
}

#conteudo-principal .info-wrapper .info {
  border-right: 2px solid var(--cor-azul-1);
  justify-content: space-between;
  width: 100%;
  padding: 18px 25px 0;
  display: flex;
}

#conteudo-principal .info-wrapper .info p {
  color: var(--cor-azul-1);
  font-family: Exo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
}

#conteudo-principal .info-wrapper .info span {
  color: #666;
  font-family: Exo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
}

#conteudo-principal .equipe-wrapper {
  margin-bottom: 230px;
  position: relative;
}

#conteudo-principal .equipe-wrapper h2 {
  color: var(--cor-azul-1);
  font-family: Exo;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 43px;
}

#conteudo-principal .equipe-wrapper .conteudo-equipe {
  text-align: center;
  align-items: center;
  gap: 6px;
  display: flex;
}

#conteudo-principal .equipe-wrapper .conteudo-equipe h3 {
  color: var(--cor-azul-1);
  font-family: Exo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  margin: 5px !important;
}

#conteudo-principal .equipe-wrapper .conteudo-equipe p {
  color: var(--cor-preto-1);
  font-family: Exo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

#conteudo-principal .equipe-wrapper .seta-grid .seta {
  position: absolute;
  right: 55px;
}

#conteudo-principal .mais-projetos-wrapper {
  border-top: 2px solid var(--cor-azul-1);
  justify-content: flex-start;
  display: flex;
}

#conteudo-principal .mais-projetos-wrapper h2 {
  color: var(--cor-azul-1);
  border-right: 2px solid var(--cor-azul-1);
  padding-right: 50px;
  font-family: Exo;
  font-size: 32px;
  font-style: italic;
  font-weight: 500;
  line-height: 43px;
}

@media (width <= 768px) {
  .info-wrapper {
    border-top: unset !important;
    justify-content: unset !important;
    margin-bottom: 100px !important;
    display: grid !important;
  }

  .info-wrapper .info {
    margin-bottom: 25px;
    border-right: unset !important;
    padding: unset !important;
    border-top: 2px solid var(--cor-azul-1) !important;
    justify-content: flex-start !important;
    gap: 0 !important;
  }

  .info-wrapper .info p {
    width: 80px;
    border-right: 2px solid var(--cor-azul-1) !important;
    padding-top: 10px !important;
    padding-right: 0 !important;
  }

  .info-wrapper .info span {
    padding-left: 20px;
    padding-top: 10px !important;
  }

  .img-principal {
    width: 100vw !important;
    height: 300px !important;
    margin-top: 50px !important;
    margin-left: -20px !important;
    margin-right: -20px !important;
  }

  .img-principal > img {
    object-fit: cover;
    width: 100% !important;
    height: 300px !important;
  }

  #conteudo-principal .texto-wrapper .texto {
    margin-top: 45px;
  }
}

#banner {
  background: #0a0b21;
  height: 100vh;
  overflow: hidden;
}

#banner:after {
  content: "";
  background: radial-gradient(17.97% 24.82% at 72.05% 42.15%, #754a981a 0%, #3244961a 100%);
  width: 100vw;
  height: 100vh;
  display: block;
  position: absolute;
  inset: 0;
}

#banner .video-banner {
  width: 100%;
  height: 100%;
  position: relative;
}

#banner .video-banner video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#banner .video-banner:after {
  content: "";
  background: linear-gradient(#00000040 0%, #00020d00 52.08%, #02093f80 77.6%, #26004580 100%);
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  inset: 0;
}

#banner .content-banner {
  z-index: 2;
  width: fit-content;
  position: absolute;
  bottom: 9rem;
}

@media screen and (width <= 600px) {
  #banner .content-banner {
    width: 244px;
  }
}

#banner .content-banner .subtitle {
  color: #fff;
  letter-spacing: -.02em;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 1.2;
  position: relative;
}

@media (width <= 767px) {
  #banner .content-banner .subtitle {
    font-size: 2rem;
  }
}

@media screen and (width <= 600px) {
  #banner .content-banner .subtitle {
    font-size: 1.5rem;
  }
}

#banner .content-banner .title {
  color: #fff;
  letter-spacing: -.02em;
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.2;
}

@media (width <= 767px) {
  #banner .content-banner .title {
    font-size: 3rem;
  }
}

@media screen and (width <= 600px) {
  #banner .content-banner .title {
    font-size: 2rem;
  }
}

#portfolio {
  margin-top: -5rem;
  position: relative;
}

@media screen and (width <= 600px) {
  #portfolio {
    background: linear-gradient(90deg, #00020d 0%, #07000d 100%);
  }
}

#portfolio .orn-sections {
  display: none;
}

@media screen and (width <= 600px) {
  #portfolio .orn-sections {
    display: block;
  }
}

#portfolio .swiper-portfolio {
  width: 100%;
  display: grid;
}

@media screen and (width <= 768px) {
  #portfolio .swiper-portfolio {
    margin-top: 12rem;
  }
}

@media screen and (width <= 600px) {
  #portfolio .swiper-portfolio {
    margin-top: 9rem;
  }
}

#portfolio .swiper-portfolio .swiper-wrapper {
  transition-timing-function: linear;
}

@media (width <= 1661px) {
  #portfolio .swiper-portfolio .swiper-wrapper {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

@media (width >= 1024px) {
  #portfolio .swiper-portfolio .swiper-wrapper {
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    gap: 1rem;
    display: grid;
  }
}

@media screen and (width <= 600px) {
  #portfolio .swiper-portfolio .swiper-wrapper {
    grid-template-columns: 1fr;
  }
}

@media (width <= 1367px) {
  #portfolio .swiper-portfolio .swiper-wrapper .swiper-slide {
    width: 100%;
  }
}

#portfolio .swiper-portfolio .swiper-wrapper .swiper-slide:first-child {
  grid-column-start: 2;
}

#portfolio .nav-wrapper {
  display: none;
}

@media screen and (width <= 600px) {
  #portfolio .nav-wrapper {
    display: block;
  }

  #portfolio .nav-wrapper .swiper-pagination-bullet {
    background: var(--primary-pure-10);
  }

  #portfolio .nav-wrapper .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: var(--primary-pure-10);
    border-radius: 1.3125rem;
    width: 1.5rem;
    height: .4375rem;
  }

  #portfolio .cards {
    padding: .5rem 0 4rem 1rem;
  }
}

#portfolio .cards .card-principal {
  background: linear-gradient(90deg, #00020d 0%, #07000d 100%);
  width: 465.5px;
  height: 448px;
  position: absolute;
}

@media (width <= 1661px) {
  #portfolio .cards .card-principal {
    width: 537px;
  }
}

@media screen and (width <= 1440px) {
  #portfolio .cards .card-principal {
    width: 467px;
  }
}

@media (width <= 1367px) {
  #portfolio .cards .card-principal {
    width: 439px;
  }
}

@media (width <= 1280px) {
  #portfolio .cards .card-principal {
    width: 414px;
  }
}

@media screen and (width <= 768px) {
  #portfolio .cards .card-principal {
    width: 427px;
    height: 182px;
    top: -12rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media screen and (width <= 600px) {
  #portfolio .cards .card-principal {
    width: 327px;
    height: 152px;
    top: -2rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

#portfolio .cards .card-principal .content {
  position: absolute;
  top: 8rem;
  left: 4rem;
}

@media screen and (width <= 768px) {
  #portfolio .cards .card-principal .content {
    top: 1.5rem;
    left: 1.5rem;
  }
}

#portfolio .cards .card-principal .content .icon {
  width: 64px;
  height: 64px;
  display: block;
}

@media screen and (width <= 768px) {
  #portfolio .cards .card-principal .content .icon {
    width: 32px;
    height: 32px;
  }
}

#portfolio .cards .card-principal .content .subtitle {
  letter-spacing: -.02em;
  color: var(--primary-pure-10);
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 120%;
}

@media screen and (width <= 768px) {
  #portfolio .cards .card-principal .content .subtitle {
    font-size: 1.5rem;
  }
}

@media screen and (width <= 600px) {
  #portfolio .cards .card-principal .content .subtitle {
    font-size: 1.125rem;
    line-height: 144%;
  }
}

#portfolio .cards .card-principal .content .title {
  letter-spacing: -.02em;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, #5574ff 0%, #c47cff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 114%;
}

@media screen and (width <= 768px) {
  #portfolio .cards .card-principal .content .title {
    font-size: 2rem;
  }
}

@media screen and (width <= 600px) {
  #portfolio .cards .card-principal .content .title {
    font-size: 1.5rem;
    line-height: 133%;
  }
}

#portfolio .cards .card {
  cursor: pointer;
  width: 100%;
  height: 100%;
  transition: all .3s ease-in;
  position: relative;
}

#portfolio .cards .card .img-card {
  width: 100%;
  height: 448px;
  position: relative;
}

@media (width <= 1661px) {
  #portfolio .cards .card .img-card {
    width: 100%;
  }
}

@media (width <= 512px) {
  #portfolio .cards .card .img-card {
    width: 95%;
    height: 350px;
  }
}

@media (width <= 440px) {
  #portfolio .cards .card .img-card {
    width: 312px;
    height: 312px;
  }
}

#portfolio .cards .card .img-card img, #portfolio .cards .card .img-card video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

#portfolio .cards .card .img-card:after {
  content: "";
  background: linear-gradient(0deg, #00000080, #00000080);
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

#portfolio .cards .card .content {
  border-left: 2px solid var(--primary-pure-10);
  background: linear-gradient(270deg, #ffffff01 0%, #ffffff1a 100%);
  width: 100%;
  height: 5.8125rem;
  padding: 1rem;
  transition: all .3s ease-in;
  position: absolute;
  bottom: 0;
  overflow: hidden;
}

#portfolio .cards .card .content .tag {
  color: #fff;
  backdrop-filter: blur(25px);
  text-transform: uppercase;
  text-align: center;
  letter-spacing: .1em;
  background: #ffffff1a;
  border: 1px solid #fff;
  border-radius: 18px;
  width: fit-content;
  padding: .25rem .75rem;
  font-size: .75rem;
  font-weight: 600;
  line-height: 133%;
}

@media screen and (width <= 600px) {
  #portfolio .cards .card .content .tag {
    font-size: 10px;
  }
}

#portfolio .cards .card .content .title {
  letter-spacing: -.02em;
  color: var(--primary-pure-10);
  margin-top: .5rem;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 133%;
}

#portfolio .cards .card .content .description {
  letter-spacing: -.02em;
  color: var(--primary-pure-10);
  max-width: 412px;
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
}

@media (width <= 1661px) {
  #portfolio .cards .card .content .description {
    width: 85%;
    max-width: 100%;
  }
}

@media (width <= 899px) {
  #portfolio .cards .card.active .content {
    height: 100%;
  }
}

@media (width >= 900px) {
  #portfolio .cards .card:hover .content {
    height: 100%;
  }
}

#sobre {
  background: #0a0b21;
  padding: 12.5rem 0 7.5rem;
  position: relative;
}

@media screen and (width <= 768px) {
  #sobre {
    padding: 6rem 0 .875rem;
  }
}

@media screen and (width <= 600px) {
  #sobre {
    padding: 3rem 0 .875rem;
  }
}

#sobre .content {
  justify-content: space-between;
  display: flex;
}

@media screen and (width <= 768px) {
  #sobre .content {
    flex-direction: column;
  }
}

#sobre .content .col-1 {
  width: 470px;
}

@media screen and (width <= 600px) {
  #sobre .content .col-1 {
    width: 306px;
  }
}

#sobre .content .col-1 .tag {
  color: #9b45e2;
  backdrop-filter: blur(25px);
  text-transform: uppercase;
  text-align: center;
  letter-spacing: .1em;
  background: #9b45e21a;
  border: 1px solid #9b45e2;
  border-radius: 18px;
  width: fit-content;
  padding: .25rem .75rem;
  font-size: .75rem;
  font-weight: 600;
  line-height: 133%;
}

@media screen and (width <= 600px) {
  #sobre .content .col-1 .tag {
    font-size: 10px;
  }
}

#sobre .content .col-1 .title {
  letter-spacing: -.02em;
  color: var(--primary-pure-10);
  margin-top: .5rem;
  font-size: 3rem;
  font-weight: 400;
  line-height: 114%;
}

@media screen and (width <= 600px) {
  #sobre .content .col-1 .title {
    font-size: 2rem;
  }
}

@media (width <= 1190px) {
  #sobre .content .col-1 .title {
    font-size: 2rem;
  }
}

#sobre .content .col-2 {
  width: 488px;
  margin-top: 1rem;
}

@media screen and (width <= 768px) {
  #sobre .content .col-2 {
    margin-top: 4rem;
  }
}

@media screen and (width <= 600px) {
  #sobre .content .col-2 {
    width: 327px;
    margin-top: 1rem;
  }
}

#sobre .content .col-2 .description {
  letter-spacing: -.02em;
  color: var(--primary-pure-10);
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
}

@media (width <= 1190px) {
  #sobre .content .col-2 .description {
    color: var(--neutral-30);
    font-size: .875rem;
  }
}

#sobre .servicos {
  margin-top: 13.8125rem;
}

@media (width <= 1190px) {
  #sobre .servicos {
    margin-top: 7.5rem;
  }
}

@media screen and (width <= 768px) {
  #sobre .servicos {
    margin-top: 3.5rem;
  }
}

@media screen and (width <= 600px) {
  #sobre .servicos {
    margin-top: 4rem;
  }
}

#sobre .servicos .swiper-servicos-sobre {
  border: 0;
  border-bottom: 1px solid;
  border-image-source: linear-gradient(90deg, #324496 0%, #754a98 100%);
  border-image-slice: 1;
}

@media screen and (width <= 600px) {
  #sobre .servicos .swiper-servicos-sobre {
    padding-bottom: 1rem;
  }
}

#sobre .servicos .swiper-servicos-sobre .swiper-wrapper {
  transition-timing-function: linear;
}

@media screen and (width <= 600px) {
  #sobre .servicos .swiper-servicos-sobre {
    border: unset;
  }
}

#sobre .servicos .nav-wrapper {
  display: none;
}

@media screen and (width <= 600px) {
  #sobre .servicos .nav-wrapper {
    display: block;
  }

  #sobre .servicos .nav-wrapper .swiper-pagination-bullet {
    background: var(--primary-pure-10);
  }

  #sobre .servicos .nav-wrapper .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: var(--primary-pure-10);
    border-radius: 1.3125rem;
    width: 1.5rem;
    height: .4375rem;
  }
}

#sobre .servicos .title {
  text-align: center;
  letter-spacing: -.02em;
  color: var(--primary-pure-10);
  margin-bottom: 1.5rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 144%;
}

@media screen and (width <= 600px) {
  #sobre .servicos .title {
    text-align: start;
    font-size: 1rem;
  }
}

#sobre .servicos .items-wrapper .item {
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  min-width: 174px;
  padding: 1.5rem 0;
  display: flex;
}

#sobre .servicos .items-wrapper .item p {
  text-align: center;
  letter-spacing: -.02em;
  color: var(--primary-pure-10);
  font-size: 1rem;
  font-weight: 400;
  line-height: 150%;
}

@media screen and (width <= 600px) {
  #sobre .servicos .items-wrapper .item p {
    color: var(--neutral-30);
    font-size: .875rem;
  }
}

#sobre .servicos .items-wrapper .item .icon {
  width: 24px;
  height: 24px;
}

#clientes {
  background: #0a0b21;
  padding: 12.5rem 0 7.5rem;
  position: relative;
}

@media screen and (width <= 1440px) {
  #clientes {
    padding: 7.5rem 0;
  }
}

@media screen and (width <= 600px) {
  #clientes {
    padding: 4.5rem 0 2rem;
  }
}

#clientes .swiper-clientes {
  width: 100%;
  display: grid;
}

#clientes .swiper-clientes .swiper-wrapper {
  transition-timing-function: linear;
}

#clientes .swiper-clientes.linha-2 {
  max-width: 1216px;
}

#clientes .content {
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

#clientes .content:after {
  content: "";
  filter: blur(103.5px);
  z-index: -1;
  background: radial-gradient(100% 100% at 100% 0, #581a8a 0%, #1a278a 100%);
  border-radius: 50%;
  width: 335px;
  height: 335px;
  position: absolute;
  bottom: 18.25rem;
  right: 3.875rem;
}

@media screen and (width <= 600px) {
  #clientes .content:after {
    bottom: 0;
    right: unset;
  }
}

#clientes .content:before {
  content: "";
  filter: blur(103.5px);
  z-index: -1;
  background: radial-gradient(100% 100% at 100% 0, #581a8a 0%, #1a278a 100%);
  border-radius: 50%;
  width: 335px;
  height: 335px;
  position: absolute;
  top: -7.1875rem;
}

@media screen and (width <= 600px) {
  #clientes .content:before {
    content: "";
    filter: blur(103.5px);
    z-index: -1;
    background: radial-gradient(100% 100% at 100% 0, #581a8a 0%, #1a278a 100%);
    border-radius: 50%;
    width: 231px;
    height: 231px;
    position: absolute;
    left: -7.1875rem;
  }
}

#clientes .content .tag {
  color: #9b45e2;
  backdrop-filter: blur(25px);
  text-transform: uppercase;
  text-align: center;
  letter-spacing: .1em;
  background: #9b45e21a;
  border: 1px solid #9b45e2;
  border-radius: 18px;
  width: fit-content;
  margin-bottom: 1rem;
  padding: .25rem .75rem;
  font-size: .75rem;
  font-weight: 600;
  line-height: 133%;
}

@media screen and (width <= 600px) {
  #clientes .content .tag {
    font-size: 10px;
  }
}

#clientes .content .title {
  letter-spacing: -.02em;
  color: var(--primary-pure-10);
  text-align: center;
  width: 376px;
  margin-bottom: 4rem;
  font-size: 3rem;
  font-weight: 400;
  line-height: 114%;
}

@media screen and (width <= 600px) {
  #clientes .content .title {
    width: 70%;
    margin-bottom: 2rem;
    font-size: 2rem;
  }
}

#clientes .logo-clientes {
  gap: 1rem;
  max-width: 1524px;
  margin: 0 auto;
  display: grid;
}

#clientes .logo-clientes .item {
  backdrop-filter: blur(25px);
  background: #ffffff0d;
  border: 1px solid #ffffff1a;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 123px;
  display: flex;
}

@media (width <= 1661px) {
  #clientes .logo-clientes .item {
    width: auto;
  }
}

@media screen and (width <= 600px) {
  #clientes .logo-clientes .item {
    height: 80px;
    padding: .5rem 1.2188rem;
  }
}

#contato {
  position: relative;
}

#contato .content-wrapper {
  justify-content: space-between;
  display: flex;
}

@media screen and (width <= 768px) {
  #contato .content-wrapper {
    flex-direction: column;
  }
}

#contato .content-wrapper .col-1 {
  max-width: 591px;
}

@media screen and (width <= 1440px) {
  #contato .content-wrapper .col-1 {
    width: 80%;
  }
}

#contato .content-wrapper .col-1 .tag {
  color: #9b45e2;
  backdrop-filter: blur(25px);
  text-transform: uppercase;
  text-align: center;
  letter-spacing: .1em;
  background: #9b45e21a;
  border: 1px solid #9b45e2;
  border-radius: 18px;
  width: fit-content;
  padding: .25rem .75rem;
  font-size: .75rem;
  font-weight: 600;
  line-height: 133%;
}

@media screen and (width <= 600px) {
  #contato .content-wrapper .col-1 .tag {
    font-size: 10px;
  }
}

#contato .content-wrapper .col-1 .title {
  letter-spacing: -.02em;
  color: var(--primary-pure-10);
  margin-top: .5rem;
  font-size: 3rem;
  font-weight: 400;
  line-height: 114%;
}

@media screen and (width <= 600px) {
  #contato .content-wrapper .col-1 .title {
    font-size: 2rem;
  }
}

@media (width <= 1367px) {
  #contato .content-wrapper .col-1 .title {
    width: 85%;
  }
}

@media screen and (width <= 600px) {
  #contato .content-wrapper .col-1 .title {
    width: 235px;
    margin-top: 1rem;
  }
}

#contato .content-wrapper .col-1 .content {
  margin-top: 4rem;
}

#contato .content-wrapper .col-1 .content .contato-item {
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
  display: flex;
}

#contato .content-wrapper .col-1 .content .contato-item .icon-contato {
  width: 32px;
  height: 32px;
}

@media screen and (width <= 600px) {
  #contato .content-wrapper .col-1 .content .contato-item .icon-contato {
    width: 24px;
    height: 24px;
  }
}

#contato .content-wrapper .col-1 .content .contato-item .info {
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

#contato .content-wrapper .col-1 .content .contato-item .info span {
  letter-spacing: .1em;
  text-transform: uppercase;
  color: var(--primary-pure-10);
  font-size: .75rem;
  font-weight: 600;
  line-height: 133%;
}

@media screen and (width <= 600px) {
  #contato .content-wrapper .col-1 .content .contato-item .info span {
    font-size: .625rem;
  }
}

#contato .content-wrapper .col-1 .content .contato-item .info a {
  letter-spacing: -.02em;
  color: var(--primary-pure-10);
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
  display: block;
  position: relative;
}

@media screen and (width <= 600px) {
  #contato .content-wrapper .col-1 .content .contato-item .info a {
    font-size: .875rem;
  }
}

#contato .content-wrapper .col-1 .content .contato-item .info a:after {
  content: "";
  background-color: #0000;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(.1, #0000), color-stop(.8, #4557e2), color-stop(.98, #4557e2));
  background-image: linear-gradient(left, transparent 10%, #4557e2 80%, #4557e2 98%);
  border-radius: .5rem;
  width: 0;
  height: 2.5px;
  transition: width .3s;
  position: absolute;
  bottom: 0;
  left: 0;
}

#contato .content-wrapper .col-1 .content .contato-item .info a:hover:after {
  width: 100%;
}

#contato .content-wrapper .col-2 {
  backdrop-filter: blur(25px);
  background: #ffffff0d;
  border: 1px solid #ffffff1a;
  border-radius: .25rem;
  width: 528px;
  padding: 2rem 1.5rem;
}

@media screen and (width <= 600px) {
  #contato .content-wrapper .col-2 {
    align-self: center;
    width: 100%;
    padding: 1.5rem 1rem;
  }
}

#contato .content-wrapper .col-2 h2 {
  letter-spacing: -.02em;
  color: var(--primary-pure-10);
  margin-bottom: 2rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 133%;
}

@media screen and (width <= 600px) {
  #contato .content-wrapper .col-2 h2 {
    font-size: 1rem;
  }
}

#contato .content-wrapper .col-2 .content-form {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

#contato .content-wrapper .col-2 .content-form input {
  width: 100%;
  height: 56px;
  color: var(--primary-pure-10);
  letter-spacing: -.02em;
  background: none;
  border: none;
  border-bottom: 1px solid #ffffff1a;
  padding: 1rem .5rem;
  font-family: Exo, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
}

#contato .content-wrapper .col-2 .content-form input::placeholder {
  color: var(--primary-pure-10);
  letter-spacing: -.02em;
  font-family: Exo, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
}

@media screen and (width <= 600px) {
  #contato .content-wrapper .col-2 .content-form input::placeholder {
    font-size: .875rem;
    line-height: 157%;
  }
}

#contato .content-wrapper .col-2 .content-form textarea {
  width: 100%;
  color: var(--primary-pure-10);
  letter-spacing: -.02em;
  background: none;
  border: none;
  border-bottom: 1px solid #ffffff1a;
  padding: .5rem;
  font-family: Exo, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
}

#contato .content-wrapper .col-2 .content-form textarea::placeholder {
  color: var(--primary-pure-10);
  letter-spacing: -.02em;
  font-family: Exo, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
}

@media screen and (width <= 600px) {
  #contato .content-wrapper .col-2 .content-form textarea::placeholder {
    font-size: .875rem;
    line-height: 157%;
  }
}

#contato .content-wrapper .col-2 .content-form .btn {
  border: 1px solid var(--primary-pure);
  letter-spacing: -.02em;
  color: var(--primary-pure);
  cursor: pointer;
  background-color: #9b45e21a;
  border-radius: 28px;
  align-self: flex-start;
  margin-top: 2rem;
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 150%;
  transition: background-color .2s ease-in, transform .1s ease-in;
}

@media screen and (width <= 600px) {
  #contato .content-wrapper .col-2 .content-form .btn {
    padding: .5rem 2rem;
    font-size: .875rem;
  }
}

#contato .content-wrapper .col-2 .content-form .btn:hover {
  box-shadow: 0 .5em .5em -.4em var(--primary-pure);
  color: var(--primary-light);
  border-color: var(--primary-pure);
  transform: translateY(-.25em) !important;
}

#contato .content-wrapper .col-2 .content-form .btn.disabled {
  color: var(--primary-pure-10);
  opacity: .5;
  pointer-events: none;
  background: #333;
  border-color: #0000;
}

#footer {
  background: #0a0b21;
  padding-bottom: 2.25rem;
  position: relative;
}

#footer .footer-wrapper {
  justify-content: space-between;
  display: flex;
}

@media screen and (width <= 600px) {
  #footer .footer-wrapper {
    flex-direction: column;
    gap: 1rem;
  }

  #footer .footer-wrapper .copy {
    align-self: center;
    width: 206px;
  }
}

#footer .footer-wrapper .copy h3 {
  letter-spacing: .1em;
  text-transform: uppercase;
  color: var(--primary-pure-10);
  margin-bottom: 1rem;
  font-size: .75rem;
  font-weight: 600;
  line-height: 133%;
}

@media screen and (width <= 600px) {
  #footer .footer-wrapper .copy h3 {
    text-align: center;
    font-size: .625rem;
  }

  #footer .footer-wrapper .logo {
    align-self: center;
  }
}

main {
  overflow: hidden;
}

.body__home #page__home .navbar {
  height: 100px;
  padding-right: 20px;
  justify-content: flex-end !important;
}

.body__home .container-banner {
  padding: 3.125rem;
}

@media screen and (width <= 1024px) {
  .body__home .container-banner {
    padding: 25px;
  }
}

.body__home.animate-end #banner .swiper-slide {
  transition: opacity 1s ease-in !important;
}

.body__home #banner {
  -webkit-user-select: none;
  user-select: none;
  height: 100vh;
  padding: 50px;
  position: relative;
  overflow: hidden;
  background: var(--cor-branco-1) !important;
}

.body__home #banner .swiper-banner {
  height: 100%;
}

.body__home #banner .swiper-slide {
  overflow: hidden;
  opacity: 0 !important;
}

.body__home #banner .swiper-slide.swiper-slide-active {
  opacity: 1 !important;
}

.body__home #banner .banner-wrapper {
  height: 100%;
  position: relative;
}

.body__home #banner .banner-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.body__home #banner .banner-wrapper .logo-wrapper {
  z-index: 10;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding: 30px;
  display: flex;
  position: absolute;
  bottom: 0;
}

.body__home #banner .banner-wrapper .logo-wrapper .logo-img {
  width: 80%;
}

.body__home .logo-animada {
  z-index: 998;
  --left: 2.125rem;
  --right: initial;
  --top: initial;
  --bottom: .625rem;
  --x: calc(50vw + 100px - 314px);
  --y: calc(-1 * (50vh - 180px));
  width: 288px;
  height: auto;
  left: var(--left);
  right: var(--right);
  top: var(--top);
  bottom: var(--bottom);
  transform: translate3d(var(--x), var(--y), 0);
  will-change: transform;
  margin: auto;
  position: absolute;
}

.body__home #banner:after {
  pointer-events: none !important;
}

.body__home #banner {
  background: #dbe9fe !important;
}

.body__home .swiper-wrapper-1 {
  z-index: 9998;
  will-change: transform;
  display: none;
  position: relative;
  transform: translateY(calc(100% + 100px))scale(1.1);
}

.body__home .menu li a {
  text-shadow: 0 1px #000000b5 !important;
}

.body__home .btn-show {
  z-index: 9998;
  position: absolute;
  top: 32px;
  left: 32px;
}

@media (width <= 768px) {
  .body__home #banner {
    padding: 20px !important;
  }

  .body__home #banner .banner-wrapper .img-branco.logo-img {
    margin: 0 auto !important;
  }

  .body__home #banner .banner-wrapper .arquitetos {
    display: none !important;
  }

  .body__home #banner .logo-wrapper {
    justify-content: center !important;
  }

  .body__home #banner .logo-animada {
    --x: 0;
    --y: calc(-1 * (50vh - 111px + 28px));
    --bottom: 16px;
    margin: 0 auto;
    width: 160px !important;
    left: 0 !important;
    right: 0 !important;
  }
}

.body__home #banner {
  position: relative;
}

.body__home #banner .swiper-slide img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.body__home #banner .swiper-banner {
  margin: 0;
  position: absolute;
  inset: 0;
}

.body__home #banner .container-banner {
  width: 100%;
  height: 100%;
  position: relative;
}

.body__home #banner .container-banner #menu {
  z-index: 999999;
}

.body__home #banner .container-banner .banner-wrapper {
  z-index: 9999;
}

.body__home #banner .banner-wrapper {
  max-width: initial !important;
  width: 100% !important;
}

.body__home #banner .banner-wrapper .btn-show {
  top: 0 !important;
  left: 0 !important;
}

.body__home #banner .logo-wrapper {
  padding: 0 !important;
}

.body__home #menu {
  z-index: 2147483647;
  position: absolute;
  height: 50px !important;
  top: 3.125rem !important;
  right: 3.125rem !important;
}

@media screen and (width <= 1024px) {
  .body__home #menu {
    top: 25px !important;
    right: 25px !important;
  }
}

.body__home #menu .menu-lateral {
  pointer-events: all !important;
  height: 50px !important;
}

.body__home #menu .menu-lateral .navbar {
  height: 50px !important;
}

@media screen and (width <= 1024px) {
  .body__home .logo-wrapper .logo-img {
    width: 140px !important;
  }
}

.body__projetos #page__projetos {
  flex-direction: column;
  min-height: 100vh;
  padding: 25px 50px 0;
  display: flex;
}

.body__projetos #page__projetos .img-wrapper {
  gap: unset !important;
  margin-bottom: unset !important;
  display: unset !important;
}

.body__projetos #page__projetos .container-projetos-mobile {
  display: none;
}

.body__projetos #page__projetos #container-projeto {
  justify-content: space-between;
  gap: 100px;
  display: flex;
}

.body__projetos #page__projetos .container-projeto {
  gap: 40px;
  height: 100%;
  display: grid;
}

.body__projetos #page__projetos .projeto-wrapper {
  cursor: pointer;
  max-width: 283px;
  height: 100%;
}

.body__projetos #page__projetos .projeto-wrapper .img-wrapper {
  --size: clamp(180px, 26vh, 17.6875rem);
  width: 100%;
  height: 100%;
  height: var(--size);
  position: relative;
  overflow: hidden;
  display: block !important;
}

.body__projetos #page__projetos .projeto-wrapper:hover img {
  transform: scale(1.1);
}

.body__projetos #page__projetos .projeto-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  margin: auto;
  transition: all .5s ease-out;
  inset: 0;
}

.body__projetos #page__projetos .projeto-wrapper .descricao-projeto {
  border-bottom: 2px solid var(--cor-azul-1);
  color: var(--cor-azul-1);
  justify-content: space-between;
  padding: 9px 0 6px;
  font-family: Exo;
  transition: all .3s;
  display: flex;
}

.body__projetos #page__projetos .projeto-wrapper .descricao-projeto p {
  transition: all .3s;
}

.body__projetos #page__projetos .projeto-wrapper:hover .descricao-projeto {
  border-bottom: 2px solid var(--cor-roxo-1);
  color: var(--cor-roxo-1);
  transition: all .3s;
}

.body__projetos #page__projetos .projeto-wrapper:hover .descricao-projeto p {
  transition: all .3s;
}

.body__projetos #page__projetos .menu-lateral {
  width: 234px;
  height: 100%;
}

.body__projetos #page__projetos .menu-lateral .zero-e-um-img img {
  display: none;
}

.body__projetos #page__projetos .menu-lateral .x-menu {
  justify-content: end;
  display: flex;
}

.body__projetos #page__projetos .menu-lateral .menu-lista {
  margin-top: 41px;
}

.body__projetos #page__projetos .menu-lateral .menu-lista li {
  text-align: right;
  padding: 20px 0 20px 20px;
}

.body__projetos #page__projetos .menu-lateral .menu-lista li a {
  color: var(--cor-azul-1);
  font-family: Exo;
  font-size: 1.5rem;
  font-weight: 500;
}

.body__projetos #page__projetos .menu-lateral .menu-lista li a:hover {
  color: var(--cor-roxo-1);
  transition: all .2s;
}

.body__projetos #page__projetos .sub-menu {
  margin-top: 32px;
  margin-bottom: 2rem;
}

.body__projetos #page__projetos .sub-menu .lista {
  border-bottom: 2px solid var(--cor-azul-1);
  -webkit-user-select: none;
  user-select: none;
  justify-content: flex-start;
  padding-bottom: 11px;
  display: flex;
}

.body__projetos #page__projetos .sub-menu .lista li {
  cursor: pointer;
  transition: all .2s;
}

.body__projetos #page__projetos .sub-menu .lista li.active a {
  color: var(--cor-roxo-1);
}

.body__projetos #page__projetos .sub-menu .lista a {
  color: var(--cor-azul-1);
  border-right: 2px solid var(--cor-azul-1);
  padding-bottom: 13px;
  padding-left: 24px;
  padding-right: 24px;
  font-family: Exo;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 27px;
}

.body__projetos #page__projetos .mySwiper {
  width: 100%;
  padding-bottom: 93px;
  height: calc(50% - 15px) !important;
}

.body__projetos #page__projetos .mySwiper .swiper-wrapper {
  flex-direction: row !important;
  align-items: baseline !important;
}

.body__projetos #page__projetos .navbar {
  justify-content: flex-end;
  padding-right: 0;
  position: fixed;
  right: 50px;
  height: 90px !important;
}

.body__projetos #page__projetos #projetos-mobile {
  display: none;
}

@media (width <= 768px) {
  .body__projetos .footer-wrapper {
    display: none !important;
  }

  .body__projetos #page__projetos {
    padding: 20px;
  }

  .body__projetos #page__projetos .navbar {
    right: 20px;
  }

  .body__projetos #page__projetos #container-projeto {
    z-index: 5;
    background: #fff;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    position: fixed;
    top: 0;
    left: 0;
  }

  .body__projetos #page__projetos .menu-lateral {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .body__projetos #page__projetos .menu-lateral .zero-e-um-img img {
    z-index: 9999;
    display: block;
    position: relative;
  }

  .body__projetos #page__projetos .mySwiper {
    display: none;
  }

  .body__projetos #page__projetos .sub-menu {
    margin-bottom: 33px;
    margin-top: 96px !important;
  }

  .body__projetos #page__projetos .sub-menu .lista a {
    padding-left: 15px;
    padding-right: 15px;
  }

  .body__projetos #page__projetos .container-projetos-mobile {
    grid-template-columns: 1fr 1fr;
    gap: 25px;
    display: grid;
  }

  .body__projetos #page__projetos .container-projetos-mobile .projeto-wrapper {
    max-width: 100%;
  }

  .body__projetos #page__projetos .container-projetos-mobile .projeto-wrapper img {
    width: 100%;
  }

  .body__projetos #page__projetos #projetos-mobile {
    display: block;
    position: relative;
    z-index: 1 !important;
  }

  .body__projetos #page__projetos #projetos-mobile .container-mobile-projetos {
    grid-template-columns: 1fr 1fr;
    gap: 25px;
    width: 100%;
    margin: 0 auto;
    display: grid;
  }
}

@media (width <= 480px) {
  .body__projetos #page__projetos .container-projetos-mobile, .body__projetos #page__projetos #projetos-mobile .container-mobile-projetos {
    grid-template-columns: 1fr;
  }
}

.body__projetos #page__projetos .swiper-btns {
  z-index: 10 !important;
  position: relative !important;
  bottom: -72px !important;
}

.body__projetos #page__projetos .swiper-btns .swiper-button-next, .body__projetos #page__projetos .swiper-btns .swiper-button-prev {
  width: 16.36px !important;
  height: 26.87px !important;
}

.body__contato #page__contato {
  background-color: var(--cor-azul-1);
  flex-direction: column;
  height: 100vh;
  padding: 50px;
  display: flex;
  overflow-y: auto !important;
}

.body__contato #page__contato .navbar {
  justify-content: flex-end;
  height: 60px;
  padding: 0 30px;
}

.body__contato #page__contato #container-projeto {
  position: fixed;
  top: 40px;
  right: 20px;
}

.body__contato #page__contato #contato {
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  width: 100%;
  margin: 100px 15vw 10vh auto;
  padding: 0;
  display: flex;
}

.body__contato #page__contato .logo img {
  height: 80px;
}

.body__contato #page__contato .menu-lateral {
  width: 234px;
  height: 100%;
}

.body__contato #page__contato .menu-lateral .x-menu {
  justify-content: end;
  display: flex;
}

.body__contato #page__contato .menu-lateral .menu-lista {
  margin-top: 41px;
}

.body__contato #page__contato .menu-lateral .menu-lista li {
  text-align: right;
  padding: 20px 0 20px 20px;
}

.body__contato #page__contato .menu-lateral .menu-lista li a {
  color: var(--cor-branco-1);
  font-family: Exo;
  font-size: 24px;
  font-weight: 500;
}

.body__contato #page__contato .menu-lateral .menu-lista li a:hover {
  color: var(--cor-verde-1);
  transition: all .2s;
}

.body__contato #contato {
  max-width: 744px;
  margin: 0 auto;
  position: unset !important;
}

.body__contato #contato .contato-wrapper {
  border-bottom: 2px solid var(--cor-branco-1);
  margin-top: 24px;
  padding-bottom: 26px;
}

.body__contato #contato .contato-wrapper h2 {
  color: var(--cor-verde-1);
  margin-bottom: 13px;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
}

.body__contato #contato .contato-wrapper p, .body__contato #contato .contato-wrapper a {
  color: var(--cor-branco-1);
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
}

.body__contato #contato .contato-wrapper p:hover, .body__contato #contato .contato-wrapper a:hover {
  color: var(--cor-roxo-1);
  transition: all .2s;
}

.body__contato .footer-wrapper {
  width: 100%;
  padding-right: 100px;
  position: fixed;
  bottom: 50px;
}

@media (width <= 768px) {
  .body__contato #page__contato {
    padding: 20px;
  }

  .body__contato #page__contato .logo {
    margin-top: -20px;
  }

  .body__contato #page__contato #container-projeto {
    top: 20px;
  }

  .body__contato #page__contato #container-projeto .navbar {
    padding: 0;
  }

  .body__contato #page__contato .navbar {
    align-items: unset;
  }

  .body__contato #page__contato #contato {
    margin: unset;
  }

  .body__contato #page__contato #contato .contato-wrapper {
    border-bottom: 2px solid var(--cor-branco-1);
    margin-top: 24px;
    padding-bottom: 26px;
  }

  .body__contato #page__contato #contato .contato-wrapper h2 {
    color: var(--cor-verde-1);
    margin-bottom: 13px;
    font-size: 14px;
    font-weight: 500;
    line-height: 32px;
  }

  .body__contato #page__contato #contato .contato-wrapper p, .body__contato #page__contato #contato .contato-wrapper a {
    color: var(--cor-branco-1);
    font-size: 12px;
    font-weight: 500;
    line-height: 27px;
  }

  .body__contato .menu-lateral .navbar .hamburger-menu {
    margin-right: unset !important;
  }
}

.body__sobre #page__sobre {
  background-color: var(--cor-branco-2);
  padding: 50px;
}

.body__sobre #page__sobre .menu-lateral .navbar {
  align-items: flex-start;
  position: fixed;
  right: 50px;
  padding: 0 !important;
}

.body__sobre #page__sobre .footer-wrapper img {
  background-color: var(--cor-branco-2);
  margin-bottom: -1.5rem;
  padding: 1.5rem 0;
}

.body__sobre #page__sobre .seta-grid {
  justify-content: end;
  margin-bottom: 100px;
  display: flex;
}

.body__sobre #container-sobre {
  justify-content: space-between;
  display: flex;
}

.body__sobre .img-sobre {
  max-width: 1550px;
  position: relative;
}

.body__sobre .img-sobre:hover .img-azul-sobre {
  opacity: 0;
  transition: all .4s ease-in-out;
}

.body__sobre .img-azul-sobre {
  opacity: 1;
  transition: all .4s ease-in-out;
  position: absolute;
  top: 0;
}

.body__sobre .img-sobre-mobile, .body__sobre .zero-e-um-img img {
  display: none;
}

.body__sobre #page__sobre .menu-lateral {
  width: 234px;
  height: 100%;
}

.body__sobre #page__sobre .menu-lateral .x-menu {
  justify-content: end;
  display: flex;
}

.body__sobre #page__sobre .menu-lateral .menu-lista {
  margin-top: 41px;
}

.body__sobre #page__sobre .menu-lateral .menu-lista li {
  text-align: right;
  padding: 20px 0 20px 20px;
}

.body__sobre #page__sobre .menu-lateral .menu-lista li a {
  color: var(--cor-azul-1);
  font-family: Exo;
  font-size: 1.5rem;
  font-weight: 500;
}

.body__sobre #page__sobre .menu-lateral .menu-lista li a:hover {
  color: var(--cor-roxo-1);
  transition: all .2s;
}

.body__sobre .container-conteudo {
  max-width: 759px;
  margin: 0 auto;
}

.body__sobre .texto-sobre {
  margin-top: 139px;
  margin-bottom: 121px;
}

.body__sobre .texto-sobre p {
  margin-top: 32px;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 27px;
}

.body__sobre #conteudo-sobre .conteudo-wrapper {
  margin-bottom: 152px;
}

.body__sobre #conteudo-sobre .conteudo-wrapper h1 {
  color: var(--cor-azul-1);
  font-size: 2rem;
  font-weight: 500;
  line-height: 43px;
}

.body__sobre #conteudo-sobre .conteudo-wrapper .wrapper {
  border-top: 2px solid #000;
  padding: 25px 0 27px;
  position: relative;
}

.body__sobre #conteudo-sobre .conteudo-wrapper .wrapper .img-socio {
  opacity: 0;
  width: 280px;
  height: 311px;
  transition: all .3s;
  position: absolute;
  top: 0;
  left: -40%;
}

.body__sobre #conteudo-sobre .conteudo-wrapper .wrapper .img-socio img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.body__sobre #conteudo-sobre .conteudo-wrapper .wrapper .img-socio-ultima {
  bottom: 0;
  top: unset;
}

.body__sobre #conteudo-sobre .conteudo-wrapper .wrapper h2 {
  color: var(--cor-azul-1);
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 32px;
}

.body__sobre #conteudo-sobre .conteudo-wrapper .wrapper:hover .img-socio {
  opacity: 1;
  transition: all .5s ease-in-out;
}

.body__sobre #conteudo-sobre .conteudo-wrapper .wrapper:hover h2 {
  color: var(--cor-roxo-1);
  transition: all .3s;
}

.body__sobre #conteudo-sobre .conteudo-wrapper .wrapper span {
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.body__sobre #conteudo-sobre .conteudo-wrapper .wrapper p {
  margin-top: 18px;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 27px;
}

.body__sobre #conteudo-sobre .conteudo-wrapper .wrapper:last-child {
  border-bottom: 2px solid #000;
}

.body__sobre .premicoes-wrapper {
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
  border-bottom: unset !important;
  padding: 0 0 24px !important;
}

.body__sobre .premicoes-wrapper p {
  max-width: 406px;
  transition: all .3s ease-in-out;
  margin-top: 0 !important;
}

.body__sobre .premicoes-wrapper span {
  border-left: 2px solid #000;
  padding-top: 54px;
  padding-left: 33px;
  transition: all .3s ease-in-out;
}

.body__sobre .premicoes-wrapper:hover p, .body__sobre .premicoes-wrapper:hover span {
  color: var(--cor-roxo-1);
}

@media (width <= 768px) {
  .body__sobre #page__sobre {
    padding: 20px;
  }

  .body__sobre #page__sobre:hover .img-socio {
    display: none;
  }

  .body__sobre #page__sobre #container-sobre {
    height: 60px;
  }

  .body__sobre #page__sobre .menu-lateral .navbar {
    align-items: center;
    right: 20px;
  }

  .body__sobre #page__projetos #container-projeto {
    height: 60px;
  }

  .body__sobre .texto-sobre {
    margin-top: 13px;
  }

  .body__sobre #container-sobre {
    z-index: 9;
    background: #fff;
    flex-direction: column-reverse;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
  }

  .body__sobre #container-sobre .menu-lateral {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .body__sobre #container-sobre .menu-lateral .zero-e-um-img img {
    z-index: 9999;
    display: block;
    position: relative;
  }

  .body__sobre .img-sobre-mobile {
    max-width: 1513px;
    margin-top: 100px;
    display: block;
    position: relative;
  }

  .body__sobre .img-sobre-mobile:hover .img-azul-sobre {
    opacity: 0;
    transition: all .4s ease-in-out;
  }

  .body__sobre .img-sobre {
    display: none;
  }

  .body__sobre #conteudo-sobre .conteudo-wrapper {
    margin-bottom: 94px;
    padding: 28px 0;
  }

  .body__sobre #conteudo-sobre .conteudo-wrapper h1 {
    margin-bottom: 20px;
  }

  .body__sobre .seta-grid {
    margin-bottom: 20px !important;
  }
}

.body__rui_barbosa #page__rui_barbosa {
  padding: 50px;
}

.body__rui_barbosa #page__rui_barbosa .img-dupla img {
  max-width: initial;
}

@media (width <= 768px) {
  .body__rui_barbosa #page__rui_barbosa {
    padding: 20px;
  }

  .body__rui_barbosa #page__rui_barbosa .img-dupla img {
    max-width: 100% !important;
  }
}

.body__senge #page__senge {
  padding: 50px;
}

.body__senge #page__senge .img-dupla img {
  max-width: initial;
}

@media (width <= 768px) {
  .body__senge #page__senge {
    padding: 20px;
  }

  .body__senge #page__senge .img-dupla img {
    max-width: 100% !important;
  }

  .body__senge #page__senge .conteudo-tx {
    display: grid;
  }
}

.body__expo #page__expo {
  padding: 50px;
}

.body__expo #page__expo .img-dupla img {
  max-width: initial;
}

@media (width <= 768px) {
  .body__expo #page__expo {
    padding: 20px;
  }

  .body__expo #page__expo .img-dupla img {
    max-width: 100% !important;
  }
}

.body__0e1 #page__0e1 {
  padding: 50px;
}

.body__0e1 #page__0e1 .img-dupla img {
  max-width: initial !important;
}

@media (width <= 1100px) {
  .body__0e1 #page__0e1 .img-dupla img {
    max-width: 598px !important;
  }
}

@media (width <= 768px) {
  .body__0e1 #page__0e1 {
    padding: 20px;
  }

  .body__0e1 #page__0e1 .img-dupla img {
    max-width: 100% !important;
  }
}

.body__mel #page__mel {
  padding: 50px;
}

.body__mel #page__mel .img-dupla img {
  max-width: initial;
}

.body__mel #page__mel .primeira-img-mel {
  grid-template-columns: 34% 1fr;
  display: grid;
}

.body__mel #page__mel .primeira-img-mel img {
  grid-column: 2;
}

@media (width <= 768px) {
  .body__mel #page__mel {
    padding: 20px;
  }

  .body__mel #page__mel .img-dupla img {
    max-width: 100% !important;
  }
}

.body__oden #page__oden {
  padding: 50px;
}

.body__oden #page__oden .img-dupla img {
  max-width: initial;
}

@media (width <= 768px) {
  .body__oden #page__oden {
    padding: 20px;
  }

  .body__oden #page__oden .img-dupla img {
    max-width: 100% !important;
  }
}

.body__tito #page__tito {
  padding: 50px;
}

.body__tito #page__tito .img-dupla img {
  max-width: initial;
}

@media (width <= 768px) {
  .body__tito #page__tito {
    padding: 20px;
  }

  .body__tito #page__tito .img-dupla img {
    max-width: 100% !important;
  }
}
/*# sourceMappingURL=style.css.map */

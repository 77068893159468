@import "./salebrand_mixins.scss";

#sobre{
    padding: 12.5rem 0 7.5rem 0;
    position: relative;
    background: #0A0B21;
    @include d(md2){
        padding: 6rem 0 .875rem 0;
    }
    @include d(sm){
        padding: 3rem 0 .875rem 0;
    }
    .content{
        display: flex;
        justify-content: space-between;
        @include d(md2){
            flex-direction: column;
        }
        .col-1{
            width: 470px;
            @include d(sm){
                width: 306px;
            }
            .tag {
                @include TagTitle();
            }
            .title {
                @include Title(3rem);
                margin-top: 0.5rem;
                @media (max-width: 1190px) {
                    font-size: 2rem;
                }
            }
        }
        .col-2{
            width: 488px;
            margin-top: 1rem;
            @include d(md2){
                margin-top: 4rem;
            }
            @include d(sm){
                width: 327px;
                margin-top: 1rem;
            }
            .description {
                font-size: 1rem;
                font-weight: 300;
                line-height: 150%;
                letter-spacing: -0.02em;
                color: var(--primary-pure-10);
                @media (max-width: 1190px) {
                    font-size: 0.875rem;
                    color: var(--neutral-30);
                }
            }
        }
    }
    .servicos{
        margin-top: 13.8125rem;
        @media (max-width: 1190px){
            margin-top: 7.5rem;
        }
        @include d(md2){
            margin-top: 3.5rem;
        }
        @include d(sm){
            margin-top: 4rem;
        }
        .swiper-servicos-sobre{
            @include d(sm){
            padding-bottom: 1rem;
            }
            .swiper-wrapper {
                transition-timing-function: linear;
            }
            @include Gradient();
            border-left: 0;
            border-right: 0;
            border-top: 0;
            @include d(sm){
                border: unset;
            }
        }
        .nav-wrapper{
            display: none;
            @include d(sm){
                display: block;
                .swiper-pagination-bullet{
                    background: var(--primary-pure-10);
                    &.swiper-pagination-bullet-active{
                        background: var(--primary-pure-10);
                        width: 1.5rem;
                        height: .4375rem;
                        border-radius: 1.3125rem;
                    }
                }
            }
        }
        .title{
            font-weight: 400;
            font-size: 1.125rem;
            line-height: 144%;
            text-align: center;
            letter-spacing: -0.02em;
            color: var(--primary-pure-10);
            margin-bottom: 1.5rem;
            @include d(sm){
                font-size: 1rem;
                text-align: start;
            }
        }
        .items-wrapper{
            .item{
                display: flex;
                min-width: 174px;
                align-items: center;
                flex-direction: column;
                padding: 1.5rem 0;
                gap: 1rem;
                p{
                    font-weight: 400;
                    font-size: 1rem;
                    text-align: center;
                    line-height: 150%;
                    letter-spacing: -0.02em;
                    color: var(--primary-pure-10);
                    @include d(sm){
                        font-size: 0.875rem;
                        color: var(--neutral-30);
                    }
                }
                .icon{
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
}
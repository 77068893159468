@import "./salebrand_mixins.scss";

#contato{
//    padding: 13.25rem 0;
   position: relative;
    //background: #0A0B21;
//    @include d(lg){
//         padding: 7.5rem 0;
//    }
//    @include d(sm){
//         padding: 3rem 0 3.5rem 0;
//    }
   .content-wrapper{
        display: flex;
        justify-content: space-between;
        @include d(md2){
            flex-direction: column;
        }
        .col-1{  
            max-width: 591px;
            @include d(lg){
                width: 80%;
            }
            .tag {
                @include TagTitle();
            }
            .title {
                @include Title(3rem);
                margin-top: 0.5rem;
                @media (max-width: 1367px) {
                    width: 85%;
                }
                @include d(sm){
                    margin-top: 1rem;
                    width: 235px;
                }
            }
            .content{
                margin-top: 4rem;
                .contato-item{
                    display: flex;
                    gap: 1rem;
                    align-items: center;
                    margin-bottom: 1.5rem;
                    .icon-contato{
                        width: 32px;
                        height: 32px;
                        @include d(sm){
                            width: 24px;
                            height: 24px;
                        }
                    }
                    .info{
                        display: flex;
                        flex-direction: column;
                        gap: .5rem;
                        span{
                            font-weight: 600;
                            font-size: .75rem;
                            line-height: 133%;
                            letter-spacing: 0.1em;
                            text-transform: uppercase;
                            color: var(--primary-pure-10);
                            @include d(sm){
                                font-size: .625rem;
                            }
                        }
                        a{
                            display: block;
                            font-weight: 300;
                            font-size: 1rem;
                            line-height: 150%;
                            letter-spacing: -0.02em;
                            color: var(--primary-pure-10);
                            position: relative;
                            @include d(sm){
                                font-size: .875rem;
                            }
                            &::after {
                              content: "";
                              position: absolute;
                              left: 0;
                              bottom: 0;
                              height: 2.5px;
                              width: 0;
                              border-radius: .5rem;
                              background-color: rgba(0,0,0,0);
                              background-image: -webkit-gradient(linear, left top, right top, color-stop(10%, transparent), color-stop(80%, #4557E2), color-stop(98%, #4557E2));
                              background-image: linear-gradient(left, transparent 10%, #4557E2 80%, #4557E2 98%);
                              -webkit-transition: width .3s ease;
                              transition: width .3s ease;
                            }
                            &:hover::after {
                              width: 100%;
                            }
                        }
                    }
                }
            }
        }
        .col-2{    
            background: rgba(255, 255, 255, 0.05);
            border: 1px solid rgba(255, 255, 255, 0.1);
            backdrop-filter: blur(25px);
            border-radius: .25rem;
            padding: 2rem 1.5rem;
            width: 528px;
            @include d(sm){
                width: 100%;
                align-self: center;
                padding: 1.5rem 1rem;
            }
            h2{
                font-weight: 700;
                font-size: 1.5rem;
                line-height: 133%;
                letter-spacing: -0.02em;
                color: var(--primary-pure-10);
                margin-bottom: 2rem;
                @include d(sm){
                    font-size: 1rem;
                }
            }
            .content-form{
                display: flex;
                flex-direction: column;
                gap: 1rem;
                input{
                    font-family: $font-1;
                    padding: 1rem .5rem;
                    width: 100%;
                    height: 56px;
                    background: transparent;
                    border: none;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                    color: var(--primary-pure-10);
                    font-size: 1rem;
                    font-weight: 300;
                    line-height: 150%;
                    letter-spacing: -0.02em;
                    &::placeholder{  
                        font-family: $font-1;
                        color: var(--primary-pure-10);
                        font-size: 1rem;
                        font-weight: 300;
                        line-height: 150%;
                        letter-spacing: -0.02em;
                        @include d(sm){
                            font-size: .875rem;
                            line-height: 157%;
                        }
                    }
                }
                textarea{
                    font-family: $font-1;
                    width: 100%;
                    padding: .5rem;
                    background: transparent;
                    border: none;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                    color: var(--primary-pure-10);
                    font-size: 1rem;
                    font-weight: 300;
                    line-height: 150%;
                    letter-spacing: -0.02em;
                    &::placeholder{  
                        font-family: $font-1;
                        color: var(--primary-pure-10);
                        font-size: 1rem;
                        font-weight: 300;
                        line-height: 150%;
                        letter-spacing: -0.02em;
                        @include d(sm){
                            font-size: .875rem;
                            line-height: 157%;
                        }
                    }
                }
                .btn{
                    margin-top: 2rem;
                    padding: 1rem 2rem;
                    background-color: rgba(155, 69, 226, 0.1);
                    border: 1px solid var(--primary-pure);
                    border-radius: 28px;
                    align-self: flex-start;
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 150%;
                    letter-spacing: -0.02em;
                    color: var(--primary-pure);
                    transition: background-color .2s ease-in, transform .1s ease-in;
                    cursor: pointer;
                    @include d(sm){
                        font-size: .875rem;
                        padding: .5rem 2rem;
                    }
                    &:hover{
                        box-shadow: 0 0.5em 0.5em -0.4em var(--primary-pure);
                        transform: translateY(-0.25em) !important;
                        color: var(--primary-light);
                        border-color: var(--primary-pure);
                    }
                    &.disabled{
                        background: #333333;
                        border-color: transparent;
                        color: var(--primary-pure-10);
                        opacity: .5;
                        pointer-events: none;
                    }
                }
            }
        }
   }
}
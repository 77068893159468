@import "./salebrand_mixins.scss";

#banner {
    overflow: hidden;
    height: 100vh;
    background: #0A0B21;
    &::after {
      content: "";
      position: absolute;
      display: block;
      inset: 0;
      width: 100vw;
      height: 100vh;
      background: radial-gradient(
        17.97% 24.82% at 72.05% 42.15%,
        rgba(117, 74, 152, 0.1) 0%,
        rgba(50, 68, 150, 0.1) 100%
      );
    }
    .video-banner {
      position: relative;
      width: 100%;
      height: 100%;
      // padding-bottom: 56.25%;
      video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &::after {
        content: "";
        position: absolute;
        display: block;
        inset: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.25) 0%,
          rgba(0, 2, 13, 0) 52.08%,
          rgba(2, 9, 63, 0.5) 77.6%,
          rgba(38, 0, 69, 0.5) 100%
        );
      }
    }
    .content-banner {
      position: absolute;
      bottom: 9rem;
      width: fit-content;
      z-index: 2;
      @include d(sm){
        width: 244px;
      }
      .subtitle {
        position: relative;
        font-size: 2.5rem;
        color: #fff;
        line-height: 1.2;
        font-weight: 400;
        letter-spacing: -0.02em;
        @media (max-width: 767px){
          font-size: 2rem;
        }
        @include d(sm){
          font-size: 1.5rem;
        }
      }
      .title {
        font-size: 4rem;
        color: #fff;
        line-height: 1.2;
        font-weight: 700;
        letter-spacing: -0.02em;
        @media (max-width: 767px){
          font-size: 3rem;
        }
        @include d(sm){
          font-size: 2rem;
        }
      }
    }
  }
  
@mixin Title($font-size: 1.5rem){
    font-size: $font-size;
    font-weight: 400;
    line-height: 114%;
    letter-spacing: -0.02em;
    color: #{var(--primary-pure-10)};
    @include d(sm){
        font-size: 2rem;
    }
}

@mixin TagTitle($background: rgba(155, 69, 226, 0.1), $color: #9B45E2){
    background: $background;
    color: $color;
    border: 1px solid #9B45E2;
    backdrop-filter: blur(25px);
    padding: 0.25rem 0.75rem;
    border-radius: 18px;
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 133%;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.1em;
    width: fit-content;
    @include d(sm){
        font-size: 10px;
    }
}

@mixin Gradient() {
    border: 1px solid;
    border-image-slice: 1;
    border-width: 1px;
    border-image-source: linear-gradient(90deg, #324496 0%, #754A98 100%);  
  }

@mixin OrnamentoSection($width: 335px, $height: 335px){
    content: "";
    position: absolute;
    width: $width;
    height: $height;
    border-radius: 50%;
    background: radial-gradient(100% 100% at 100% 0%, #581A8A 0%, #1A278A 100%);
    filter: blur(103.5px);
    z-index: -1;
}

// @import "../components/secao-contato.scss";
#page__home {
  .navbar {
    justify-content: flex-end !important;
    height: 100px;
    padding-right: 20px;

  }
}

.container-banner {
  padding: 3.125rem;
  @include d(md) {
    padding: 25px;
  }
}
&.animate-end #banner .swiper-slide {
  transition: 1s ease-in opacity !important;
}
#banner {
  padding: 50px;
  height: 100vh;
  background: $cor-branco-1 !important;
  user-select: none;
  overflow: hidden;
  position: relative;
  // @include d(s) {
  //   padding: 20px ;
  // }

  .swiper-banner {
    height: 100%;
  }

  .swiper-slide {
    /* transition: 1s ease-in opacity !important; */
    overflow: hidden;
    opacity: 0 !important;
  }
  .swiper-slide.swiper-slide-active {
    opacity: 1 !important;
  }

  .banner-wrapper {
    position: relative;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .logo-wrapper {
      position: absolute;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      z-index: 10;
      padding: 30px;
      align-items: flex-end;

      .logo-img {
        width: 80%;
      }
    }
  }
}

// .fundo-intro {
//   background: #dbe9fe;
//   position: absolute;
//   z-index: 9888;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   pointer-events: none;
// }

.logo-animada {
  // transition: all 0.3s ease;
  z-index: 998;
  width: 288px;
  height: auto;
  --left: 2.125rem;
  --right: initial;
  --top: initial;
  --bottom: 0.625rem;
  --x: calc(50vw + 100px - 314px);
  --y: calc(-1 * (50vh - 180px));
  left: var(--left);
  right: var(--right);
  top: var(--top);
  bottom: var(--bottom);
  margin: auto;
  // transition: all 0.3s;
  position: absolute;
  transform: translate3d(var(--x), var(--y), 0);
  will-change: transform;
}

#banner:after {
  pointer-events: none !important;
}
#banner {
  background: #dbe9fe !important;
}
// .banner-wrapper {

// }
.swiper-wrapper-1 {
  z-index: 9998;
  display: none;
  position: relative;
  transform: translateY(calc(100% + 100px)) scale(1.1);
  will-change: transform;
}

.menu li a {
  text-shadow: 0px 1px 0px #000000b5 !important;
}

.btn-show {
  position: absolute;
  top: 32px;
  left: 32px;
  z-index: 9998;
}

@media (max-width: 768px) {
  #banner {
    padding: 20px !important;

    .banner-wrapper .img-branco.logo-img {
      margin: 0 auto !important;
    }

    .banner-wrapper .arquitetos {
      display: none !important;
    }
    .logo-wrapper {
      justify-content: center !important;
    }
    .logo-animada {
      --x: 0;
      --y: calc(-1 * (50vh - 111px + 28px));
      --bottom: 16px;

      margin: 0 auto;
      left: 0 !important;

      right: 0 !important;
      width: 160px !important;
    }
  }
  // .logo-animada {
  //   --x: calc(50vw + 40px - 160px + 22px);
  //   --y: calc(-1 * (50vh - 111px + 28px));
  //   --bottom: 16px;
  //   --left: 16px;

  //   width: 160px !important;
  // }
}

#banner {
  position: relative;
  .swiper-slide {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .swiper-banner {
    position: absolute;
    inset: 0;
    margin: 0;
    // width:100%;
    // height:100%;
  }
  .container-banner {
    height: 100%;
    width: 100%;
    position: relative;
    #menu {
      z-index: 999999;
    }
    .banner-wrapper {
      z-index: 9999;
    }
  }
  .banner-wrapper {
    max-width: initial !important;
    width: 100% !important;
    .btn-show {
      top: 0 !important;
      left: 0 !important;
    }
  }
  .logo-wrapper {
    padding: 0 !important;
  }
}
#menu {
  height: 50px !important;
  top: 3.125rem !important;
  right: 3.125rem !important;
  position: absolute;
  z-index: 9999999999999;
  @include d(md) {
    top: 25px !important;
    right: 25px !important;
  }

  .menu-lateral {
    pointer-events: all !important;
    height: 50px !important;
    .navbar {
      height: 50px !important;
    }
  }
}

@include d(md) {
  .logo-wrapper .logo-img {
    width: 140px !important;
  }
}

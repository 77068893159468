@import "./salebrand_mixins.scss";

#clientes{
    padding: 12.5rem 0 7.5rem 0;
    position: relative;
    background: #0A0B21;
    @include d(lg){
        padding: 7.5rem 0;
    }
    @include d(sm){
        padding: 4.5rem 0 2rem 0;
    }
    
    .swiper-clientes{
        display: grid;
        width: 100%;
        .swiper-wrapper{
            transition-timing-function: linear;
        }
    }
    .swiper-clientes.linha-2{
        max-width: 1216px;
    }
    .content{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        &::after{
            @include OrnamentoSection(335px, 335px);
            right: 3.875rem;
            bottom: 18.25rem;
            @include d(sm){
                bottom: 0;
                right: unset;
            }
        }
        &::before{
             @include OrnamentoSection(335px, 335px);
             top: -7.1875rem;
             @include d(sm){
                @include OrnamentoSection(231px, 231px);
                left: -7.1875rem;
             }
        }
        .tag{
            @include TagTitle();
            margin-bottom: 1rem;
        }
        .title{
            @include Title(3rem);
            margin-bottom: 4rem;
            width: 376px;
            text-align: center;
            @include d(sm){
                width: 70%;
                margin-bottom: 2rem;
            }
        }
    }
    .logo-clientes{
        display: grid;
        gap: 1rem;
        margin: 0 auto;
        max-width: 1524px; 
        .item{
            width: 100%;
            height: 123px;
            background: rgba(255, 255, 255, 0.05);
            border: 1px solid rgba(255, 255, 255, 0.1);
            backdrop-filter: blur(25px);
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            @media (max-width: 1661px){
                width: auto;
            }
            @include d(sm){
                height: 80px;
                padding: .5rem 1.2188rem;
            }
        }
    }
}
#page__0e1{
    padding: 50px;

    .img-dupla img{
        max-width: initial !important;
    }
}

@media(max-width:1100px){
    #page__0e1{
        

        .img-dupla img{
            max-width: 598px !important;
        }
     }
}

@media(max-width:768px){
    #page__0e1{
        padding: 20px;

        .img-dupla img{
            max-width: 100% !important;
        }
     }
}


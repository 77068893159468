#page__tito{
    padding: 50px;

    .img-dupla{
        img{
            max-width: initial;
        }
    }
}


@media(max-width:768px){
    #page__tito{
        padding: 20px;

        .img-dupla{
            img{
                max-width: 100% !important;
            }
        }
     }
}
#page__projetos {
  padding: 50px;
  padding-top: 25px;
  padding-bottom: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  // height: 100vh;
  .img-wrapper {
    gap: unset !important;
    margin-bottom: unset !important;
    display: unset !important;
  }
  

  .container-projetos-mobile {
    display: none;
  }
  #container-projeto {
    display: flex;
    justify-content: space-between;
    gap: 100px;
  }
  .container-projeto {
    display: grid;
    gap: 40px;
    height: 100%;
  }

  .projeto-wrapper {
    max-width: 283px;
    height: 100%;
    cursor: pointer;

    .img-wrapper {
      --size: clamp(180px, 26vh, 17.6875rem);
      display: block !important;
      height: 100%;
      width: 100%;
      // width: var(--size);
      height: var(--size);
      position: relative;
      overflow: hidden;
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
    img {
      // position: absolute;
      inset: 0;
      margin: auto;
      height: 100%;
      width: 100%;
      transition: ease-out 0.5s;
      object-fit: cover;
    }

    .descricao-projeto {
      display: flex;
      justify-content: space-between;
      padding: 9px 0 6px;
      border-bottom: 2px solid $cor-azul-1;
      font-family: "Exo";
      color: $cor-azul-1;
      transition: 0.3s;
      p {
        transition: 0.3s;
      }
    }
    &:hover .descricao-projeto {
      border-bottom: 2px solid $cor-roxo-1;
      color: $cor-roxo-1;
      transition: 0.3s;
      p {
        // font-style: italic;
        transition: 0.3s;
      }
    }
  }

  .menu-lateral {
    width: 234px;
    height: 100%;
    .zero-e-um-img img {
      display: none;
    }

    .x-menu {
      display: flex;
      justify-content: end;
    }
    .menu-lista {
      margin-top: 41px;

      li {
        padding: 20px;
        padding-right: 0;
        text-align: right;
        a {
          font-weight: 500;
          font-family: "Exo";
          font-size: 1.5rem;
          color: $cor-azul-1;
          &:hover {
            color: $cor-roxo-1;
            transition: 0.2s;
            // font-style: italic;
          }
        }
      }
    }
  }

  .sub-menu {
    margin-top: 32px;
    margin-bottom: 2rem;
    
    .lista {
      display: flex;
      justify-content: flex-start;
      padding-bottom: 11px;
      border-bottom: 2px solid $cor-azul-1;
      user-select: none;
      li {
        transition: 0.2s;
        cursor: pointer;
      }
      li.active {
        a {
          color: $cor-roxo-1;
        }
      }
      a {
        font-family: "Exo";
        color: $cor-azul-1;
        font-weight: 400;
        font-size: 1.25rem;
        line-height: 27px;
        padding-right: 24px;
        padding-left: 24px;
        border-right: 2px solid $cor-azul-1;
        padding-bottom: 13px;
      }
    }
  }

  .mySwiper {
    padding-bottom: 93px;
    // display: grid;
    .swiper-wrapper {
      align-items: baseline !important;
      flex-direction: row !important;
    }
    width: 100%;
    height: calc((100% - 30px) / 2) !important;
  }
}
#page__projetos {
  .navbar {
    height: 90px !important;
    justify-content: flex-end;
    padding-right: 0px;
    position: fixed;
    right: 50px;
  }
  #projetos-mobile {
    display: none;
  }
}

@media (max-width: 768px) {
  .footer-wrapper {
    display: none !important;
  }

 

  #page__projetos {
    padding: 20px;
    
    .navbar {
      
      right: 20px;
    }

    #container-projeto {
      position: fixed;
      width: 100%;
      padding-right: 20px;
      top: 0;
      left: 0px;
      padding-left: 20px;
      background: #ffffff;
      z-index: 5;
    }
    .menu-lateral {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .zero-e-um-img img {
        display: block;
        z-index: 9999;
        position: relative;
      }
    }
    .mySwiper {
      display: none;
    }
    .sub-menu {
      margin-top: 96px !important;

      margin-bottom: 33px;
      .lista a {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .container-projetos-mobile {
      display: block;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 25px;

      .projeto-wrapper {
        max-width: 100%;
        img {
          width: 100%;
        }
      }
    }
    #projetos-mobile {
      display: block;
      z-index: 1 !important;
      position: relative;
      .container-mobile-projetos {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 25px;
        width: 100%;
        margin: 0 auto;
      }
    }
  }
}

@media (max-width: 480px) {
  #page__projetos {
    .container-projetos-mobile {
      grid-template-columns: 1fr;
    }
    #projetos-mobile {
      .container-mobile-projetos {
        grid-template-columns: 1fr;
        // max-width: 283px;
      }
    }
  }
}

#page__projetos{

  .swiper-btns {
    position: relative !important;
    z-index: 10 !important;
    bottom: -72px !important;
  }
  .swiper-btns .swiper-button-next, .swiper-btns .swiper-button-prev {
    width: 16.36px !important;
    height: 26.87px !important;
  }
}

#page__ecletica{
    padding: 50px;
    .texto-info{
        display: grid;
        gap: 25px;
    }
}


@media(max-width:768px){
    #page__ecletica{
        padding: 20px;

        .mySwiper3x{
            .swiper-slide{
                width: 100% !important;
                img{
                    max-width:100% !important;
                }
            }
        }
     }
}